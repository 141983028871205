<template>
    <div class="DashBoardSidebar" :style="{ 
        padding: reduced ? '5px' : '30px', 
        height: (reduced && isOnSmartphoneDisplay) ? '78px' : '100%',
        borderRadius: (reduced && isOnSmartphoneDisplay) ? '0 30px 0 0' : '0' }">
        <div class="OpenAndCloseSection" :style="{ marginTop: reduced ? '25px' : '0px', textAlign: reduced ? 'center' : 'right' }">
            <div v-if="!isOnSmartphoneDisplay">
                <a class="align-items-center cursor-pointer hover:surface-400 transition-duration-150 transition-colors border-round p-2" @click="manageSidebar">
                    <i v-if="!reduced" class="pi pi-times"></i>
                    <i v-if="reduced" class="pi pi-chevron-right"></i>
                </a>
            </div>
            <div v-if="isOnSmartphoneDisplay">
                <a class="align-items-center cursor-pointer hover:surface-400 transition-duration-150 transition-colors border-round p-2" @click="manageSidebar">
                    <i v-if="!reduced" class="pi pi-times"></i>
                    <i v-if="reduced" class="pi pi-arrow-up-right"></i>
                </a>
            </div>
        </div>
        <div class="CompanyNameSection" :style="{visibility: (reduced && isOnSmartphoneDisplay) ? 'hidden' : 'visible'}">
            <div v-if=" companyLogo !== null && companyLogo !== ''">
                <Avatar v-if="!reduced" :image="companyLogo" class="mr-2 mt-2 ml-2 mb-2" shape="circle" size="large"/>
                <Avatar v-if="reduced" :image="companyLogo" class="mr-2 mt-2 ml-2 mb-2" shape="circle" size="small"/>
            </div>
            <div v-else>
                <Avatar v-if="!reduced" icon="pi pi-user" class="mr-2 mt-2 ml-2 mb-2" shape="circle" size="large" style=" background-color:#EF8354; color: #FFFF"/>
                <Avatar v-if="reduced" icon="pi pi-user" class="mr-2 mt-2 ml-2 mb-2" shape="circle" size="small" style=" background-color:#EF8354; color: #FFFF"/>
            </div>
            <h3 v-if="!reduced">{{ this.companyName }}</h3>
        </div>

        <div class="buttonPagesSection" :style="{visibility: (reduced && isOnSmartphoneDisplay) ? 'hidden' : 'visible'}">
            <ul class="list-none p-0 m-0 overflow-hidden">
                <li v-for="(page, index) in pages" :key="index">
                    <a v-ripple
                       class=" flex align-items-center cursor-pointer p-3 mt-2 border-round text-100 hover:surface-400 transition-duration-150 transition-colors p-ripple"
                       :class="{ 'surface-700': activePage === index }"
                       :style="{ justifyContent: reduced ? 'center' : 'left' }"
                       @click="manageSidebarRoutings(page.label, index)">
                        <i v-if="page.label != 'Prenotazioni'" :class="page.iconClass" :style="{ marginRight: reduced ? '0px' : '15px'}"></i>
                        <i v-if="page.label == 'Prenotazioni' && waitingBookingsNumber > 0" v-badge.danger="waitingBookingsNumber" :class="page.iconClass" :style="{ marginRight: reduced ? '0px' : '15px'}"></i>
                        <i v-if="page.label == 'Prenotazioni' && waitingBookingsNumber == 0" :class="page.iconClass" :style="{ marginRight: reduced ? '0px' : '15px'}"></i>
                        <span v-if="!reduced" class="font-medium">{{ page.label }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="sidebarFooter" :style="{visibility: (reduced && isOnSmartphoneDisplay) ? 'hidden' : 'visible'}">
            <a class="align-items-center cursor-pointer hover:surface-400 transition-duration-150 transition-colors border-round p-2"
                @click="this.$router.push('/companysettings');">
                <i v-if="!reduced" class="pi pi-cog mr-2"></i>
                <i v-if="reduced" class="pi pi-cog"></i>
                <span v-if="!reduced">Impostazioni azienda</span>
            </a>
        </div>
        
    </div>
</template>

<script>
import API from '@/api/API';
import Cookies from 'js-cookie';

export default{
name: 'DashboardSidebar',
data(){
    return{
        isOnSmartphoneDisplay: window.innerWidth < 1280,
        reduced: true,
        activePage: null,
        companyLogo: '',
        waitingBookingsNumber: 0,
        pages: [
            { label: 'Statistiche', iconClass: 'pi pi-chart-bar', route:'dashboardstatistics'},
            { label: 'Prenotazioni', iconClass: 'pi pi-envelope', route:'dashboardbookings' },
            { label: 'Veicoli', iconClass: 'pi pi-car', route:'dashboardvehicles' },
            { label: 'Disponibilità', iconClass: 'pi pi-calendar', route:'dashboardcalendar' }
        ]
    }
},
mounted() {
    const jwt = Cookies.get('token');
    const companyId = Cookies.get('companyId');

    if (jwt === undefined)
    {
        console.error('Token non presente');
    }

    if (companyId === undefined)
    {
        console.error('CompanyId non presente');
    }

    API.CompanyAPI.GetCompanyLogo(companyId, jwt).then(response => {
        this.companyLogo = response.data;
    }).catch(error => {
        console.log(error);
    });

    API.CompanyAPI.GetCompanyName(companyId, jwt).then(response => {
        this.companyName = response.data;
    }).catch(error => {
        this.companyName = '{ Errore nome }';
        console.log(error);
    });

    this.waitingBookings();

    setInterval(this.waitingBookings, 180000);
},
setup() {
    
},
watch: {
    '$route'() {
        //Ottengo la route attuale e in base a quest'ultima attivo il pulsante dedicato nella sidebar
        const currentRoute = this.$route.name;
        const matchedPage = this.pages.find(page => page.route === currentRoute);
        if (matchedPage) {
          this.activePage = this.pages.indexOf(matchedPage);
        }
    }
},
methods: {
    manageSidebar(){
        this.reduced = !this.reduced;
    },
    manageSidebarRoutings(buttonClicked, index){
        this.activePage = index;
        switch(buttonClicked){
            case 'Statistiche':
                this.$router.push('/dashboardstatistics');
                break;
            case 'Prenotazioni':
                this.$router.push('/dashboardbookings');
                break;
            case 'Veicoli':
                this.$router.push('/dashboardvehicles');
                break;
            case 'Disponibilità':
                this.$router.push('/dashboardcalendar');
                break;
        }
    },
    waitingBookings() {
        const jwt = Cookies.get('token');
        const companyId = Cookies.get('companyId');

        if (jwt === undefined)
        {
            console.error('Token non presente');
        }

        if (companyId === undefined)
        {
            console.error('CompanyId non presente');
        }

        API.BookingAPI.GetWaitingBookings(jwt, companyId).then(response => {
            this.waitingBookingsNumber = response.data;
        }).catch(error => {
            console.log(error);
        });
    }
}
}
</script>

<style scoped>
:deep(.p-badge) {
    font-size: 0.70rem !important;
    min-width: 1rem !important;
    height: 1rem !important;
    line-height: 1rem !important;
}

.DashBoardSidebar{
    position: fixed;
    background-color: #4F5D75;
    height: -webkit-fill-available;
    color: white;
    z-index: 1;
    top: 0;
    text-align: center;
    -webkit-box-shadow: 5px 1px 29px 15px rgba(0,0,0,0.24); 
    box-shadow: 5px 1px 29px 15px rgba(0,0,0,0.24);
    transition: width 1s ease-in-out;
}
.CompanyNameSection{
    margin: 10px;
}
.buttonPagesSection{
    margin-top: 40px;
    border-top: 1px solid slategrey;
    padding-top: 25px;
}
.sidebarFooter{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
}
.OpenAndCloseSection{
    margin-bottom: 25px;
}
@media (max-width: 1280px) {
    .DashBoardSidebar{
        padding: 5px;
        height: 78px;
        top:unset;
        bottom: 0px;
        align-self: end;
        border-radius: 0 30px 0 0;
    }
    .CompanyNameSection{
        visibility: hidden;
    }
    .buttonPagesSection{
        visibility: hidden;
    }
    .sidebarFooter{
        visibility: hidden;
    }
}

</style>
