<template>
        <nav>
            <router-link to="/" class="navBarLogo"><img :src="MainLogoPath" alt="Logo"></router-link>
            <div class="menu-toggle text-xl" @click="toggleMenu('toggle')">
                <i class="pi pi-bars"></i>
            </div>
            <transition name="slide">
                <ul v-if="showMenu" class="text-base">
                    <router-link v-for="(link, index) in navbarLinks" :to="link.url" :key="index" @click="toggleMenu">
                        {{ link.text }}
                    </router-link>
                    <div class="navbarDivider"></div>
                    <!-- <router-link :to="{path: '/signup', query: { t: 'Azienda' }}" v-if="!loggedIn" @click="toggleMenu('signup')">Lavora con noi</router-link> -->
                    <router-link to="/login" v-if="!loggedIn" @click="toggleMenu('login')">Accedi</router-link>
                    <router-link to="/signup" v-if="!loggedIn" id="signupButton" @click="toggleMenu('signup')">Registrati</router-link>
                    <a v-if="loggedIn" class="myProfileButton" @click="toggle">
                        <Avatar type="button" icon="pi pi-user" class="mr-2" shape="circle" aria-haspopup="true" aria-controls="overlay_menu"/>
                        <Menu v-if="IsCompanyAdminUser" ref="menu" id="overlay_menu" :model="itemsCompanyAdmin" popup />
                        <Menu v-if="!IsCompanyAdminUser" ref="menu" id="overlay_menu" :model="itemsNormalUser" popup />
                        <p>{{ this.loggedInFirstName }} {{ this.loggedInLastName }}</p>
                    </a>
                </ul>
            </transition>
        </nav>
</template>

<script>
import _MainLogoPath from '../assets/Images/MainLogoNoBKG.png';
import { store } from '../store';
import API from '@/api/API';
export default{
    name: 'Navbar',
    props: {
        loggedIn: Boolean,
    },
    data(){
        return{
            navbarLinks: [
                { text: 'Cerca', url: '/' },
                { text: 'Come funziona', url: '/howitworks' },
                { text: 'Aziende', url: '/companies' },
                // { text: 'Privati', url: '/privates' },
                { text: 'Contatti', url: '/contacts' },
                //{ text: 'Supporto', url: '/support' },
            ],
            MainLogoPath: _MainLogoPath,
            showMenu: window.innerWidth >= 1280,
            isOnMobileDisplay: window.innerWidth < 1280,
            IsCompanyAdminUser: true,
            itemsNormalUser: 
            [{
                label: 'Impostazioni',
                items: 
                [ 
                    {label: 'Profilo', icon: 'pi pi-fw pi-user',
                        command: () => {
                                this.$router.push('/userprofile');
                                this.toggleMenu('userprofile');
                            }
                    }, 
                    {label: 'Le mie prenotazioni', icon: 'pi pi-fw pi-book',
                        command: () => {
                                this.$router.push('/mybookings');
                                this.toggleMenu('mybookings');
                            }
                    }, 
                    {label: 'Cambia password', icon: 'pi pi-fw pi-pencil',
                        command: () => {
                            this.$router.push('/resetpassword');
                            this.toggleMenu('changepassword');
                        }
                    }, 
                    {
                        separator: true
                    },
                    {label: 'Esci', icon: 'pi pi-fw pi-sign-out', 
                        command: () => {
                            store.commit('resetStore');
                            window.location.reload();
                        }
                    }
                ]
            }],
            itemsCompanyAdmin: 
            [{
                label: 'Impostazioni',
                items: 
                [ 
                    {label: 'Profilo', icon: 'pi pi-fw pi-user',
                        command: () => {
                                this.$router.push('/userprofile');
                                this.toggleMenu('userprofile');
                            }
                    },
                    {label: 'Dashboard azienda', icon: 'pi pi-fw pi-chart-bar',
                        command: () => {
                            this.$router.push('/dashboardstatistics');
                            this.toggleMenu('dashboardstatistics');
                        }
                    },  
                    {label: 'Cambia password', icon: 'pi pi-fw pi-pencil',
                        command: () => {
                            this.$router.push('/resetpassword');
                            this.toggleMenu('changepassword');
                        }
                    }, 
                    {
                        separator: true
                    },
                    {label: 'Esci', icon: 'pi pi-fw pi-sign-out', 
                        command: () => {
                            store.commit('resetStore');
                            window.location.reload();
                        }
                    }
                ]
            }],
        }
    },
    setup() {

    },
    computed: {
        loggedInFirstName() {
          return store.state._loggedInUser.firstName;
        },
        loggedInLastName() {
          return store.state._loggedInUser.lastName;
        },
    },
    methods: {
        toggleMenu(buttonType) {
            if(window.innerWidth < 1280){
                this.showMenu = !this.showMenu;
            }
            //Ripasso alla variabile che gestisce lo switch tra login e cambia password false, in modo da mostrare sempre la finestra di login per prima
            if (buttonType === 'login') {
                store.commit('SwitchToLoginComponent');
            }
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },

        //Metodo che serve per verificare se l'utente che si è appena loggato sia amministratore di azienda o meno.
        async checkCompanyAdminStatus() {
            const jwt = store.state._token;
            try {
                const resp = await API.UserAPI.IsCompanyAdminLogged(jwt);
                this.IsCompanyAdminUser = resp.data;
            } catch (err) {
                this.IsCompanyAdminUser = false;
            }
        },
    },
    
    created(){
        this.checkCompanyAdminStatus();
    }
}
</script>

<style scoped>

/* ----FULL RESOLUTION NAVBAR START---- */
nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(3px);
    padding: 5px 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

nav img {
    height: 2.75rem;
    margin-right: 2.5rem;
    order: -1;
    cursor: pointer;
}
.navbarDivider{
    width: 1px;
    background-color: rgb(222, 222, 222);
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    margin-right: 20px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.navBarLogo{
    margin: 0;
    padding: 0;
}
.navBarLogo:hover{
    background: none;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
}

ul {
    list-style: none;
    padding: 0;
    display: flex;
}

.center-nav-links{
    margin: 0 auto;
}

a {
    color: #000000;
    text-decoration: none;
    /* font-size: 16px; */
    font-weight: bold;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

#signupButton{
    background-color: #EF8354;
    color: #ffffff;
    text-decoration: none;
    /* font-size: 16px; */
    font-weight: bold;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 20px;
}

#signupButton:hover {
    background-color: #c96c44;
    color: #ffffff;
}

a:hover {
    background-color: #4F5D75;
    color: #ffffff;
}

.myProfileButton{
    background: none;
    display: flex;
    padding: 5px;
    cursor: pointer;
}
.myProfileButton:hover{
    background: none;
}

.myProfileButton p{
    margin: 0px;
    margin-left: 10px;
    color: #EF8354;
}

header h1 {
    color: #293d49;
    font-size: 24px;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  cursor: pointer;
}

.bar {
  background-color: #000;
  height: 2px;
  width: 25px;
  margin: 3px 0;
}
.mr-2{
    background-color:#EF8354;
    color: #ffffff;
    cursor: pointer;
}

.mr-2:hover{
    background-color: #c96c44;
    color: #ffffff;
}

/* ----FULL RESOLUTION NAVBAR END---- */
@media (max-width: 1500px) {
    nav{
        padding: 5px 120px;
    }
}

/* ----MOBILE RESOLUTION NAVBAR START---- */

@media (max-width: 1279px) {
    nav{
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px 20px;
    }
    .slide-enter-active {
        transition: 0.4s;
    }

    .slide-enter {
        transform: translateY(-100%);
    }

    .slide-leave-active {
        transition: 0.4s;
    }

    .slide-leave-to, .slide-enter-from{
        transform: translateY(-130%);
    }

    nav ul{
        box-shadow: 0px 50px 50px -4px rgba(0,0,0,0.22);
        background-color: rgba(255,255,255,98%);
        padding: 20px;
        position: absolute;
        z-index: -1; /* Necessario per far andare lo slider del Menu sotto alla navbar */
    }

    nav img {
        margin-top: 0px;
        margin-left: 0px;
        height: 40px;
    }

    .navbarDivider{
        width: auto;
        height: 1px;
        background-color: rgba(222, 222, 222,0.4);
        display: flex;
        align-content: center;
        align-items: center;
        text-align: center;
        margin: 10px;
        margin-right: 10%;
        margin-left: 10%;
    }
    .menu-toggle {
        display: flex;
      }

    ul {
        display: none;
        flex-direction: column;
        background-color: #ffffff;
        position: absolute;
        top: 40px;
        right: 0;
        width: 100%;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        padding: 20px;
    }

    ul.show {
        display: flex;
    }
    ul a {
        padding: 10px 20px;
        display: block;
        margin: 5px;
        text-align: center;
    }
}
/* ----MOBILE RESOLUTION NAVBAR END---- */
</style>
