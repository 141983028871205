<template>
  <div class="VehiclesListContainer">
    <div class="containerHeader"> 
      <i class="pi pi-list" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>I tuoi veicoli</h1>
      <p>Seleziona un veicolo dalla tua lista oppure creane uno nuovo.</p>
    </div>

    <div class="containerContent">
      <Listbox v-model="selectedVehicle" :options="vehicles" filter optionLabel="name" class="w-full" listStyle="height: 680px;"/>
    </div>
    <div class="containerFooter"> 
      <span>
        <Button label="Nuovo veicolo" :loading="loading" @click="clearVehicleFormForNewVehicle" rounded/>
      </span>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>

import API from '@/api/API';
import Cookies from 'js-cookie';

export default {
  name: 'VehiclesList',
  components: {
  },
  props: {
    formIsDirty: Boolean
  },
  data(){
    return{
      //DEBUG
      debug: false,
      selectedVehicle: null,
      vehicles: [],
      loading: false,
    }
  },
  created(){

  },
  mounted() {
    this.loadVehicles();
  },
  methods: {

    async loadVehicles() {
      const jwt = Cookies.get('token');
      const companyID = Cookies.get('companyId');
      await this.GetAllServices(jwt, companyID);

      if (this.vehicles.length > 0) {
        this.selectedVehicle = this.vehicles[0];
      }
    },

    async GetAllServices(jwt, companyId) {
      this.loading = true;
      try {
        const response = await API.ServiceAPI.GetAllServices(jwt, companyId);
        if (response.status === 200) {
          if(response.data != null){
            this.vehicles = response.data.map(service => ({
              ...service
            }));
          }
          else{
            this.$refs.toast.add({ severity: 'info', summary: 'Inserisci il primo veicolo!', detail: 'Non sono stati trovati veicoli, utilizza il form in questa pagina per inserire il primo.', life: 5000 });
          }
        } else {
          //console.log("entrato qui 1");
          this.$refs.toast.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Errore', life: 3000 });
        }
      } catch (error) {
        //console.log("entrato qui 2");
        this.$refs.toast.add({ severity: 'error', summary: 'Qualcosa è andato storto', detail: 'Errore', life: 3000 });
      } finally {
        this.loading = false;
      }
    },

    clearVehicleFormForNewVehicle(){
      this.selectedVehicle = null;
      this.$emit('clickedVehicle', null);
    }
  },
  watch: {
    async selectedVehicle(newVehicle) {
      if (newVehicle) {
        if (this.formIsDirty) {
          this.$confirm.require({
            message: `Ci sono modifiche non salvate. Sei sicuro di voler cambiare veicolo?`,
            header: 'Attenzione',
            icon: 'pi pi-exclamation-triangle',
            rejectClass: 'p-button-secondary p-button-outlined',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: () => {
              this.$emit('clickedVehicle', newVehicle);
            },
            reject: () => {
              return;
            }
          });
        } else {
          this.$emit('clickedVehicle', newVehicle);
        }
      }
    }
  }
}
</script>

<style scoped>
.VehiclesListContainer{
  padding: 70px;
  border-radius: 80px 0 0 80px;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
  position: relative;
  text-align: center;
  background-color: white;
  width: -webkit-fill-available;
  max-width: 20%;
  /* margin-right: 50px; */
}
.VehiclesListContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerContent{
  position: relative;
  align-items: normal;
  display: flex;
  margin-top: 50px;
}

.containerFooter{
  margin-top: 25px;
}

.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  min-width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}
:deep(.p-inputtext){
  max-width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}

.align-items-center{
  color: grey;
  max-width: 300px;
  margin: 15px;
}
:deep(.p-checkbox){
  margin-right: 10px !important;
}
:deep(.p-dropdown){
  width: 300px;
  text-align: left;
}
:deep(.p-dropdown .p-dropdown-label:focus){
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
}
:deep(.p-inputnumber){
  max-width: 300px;
}
:deep(.p-listbox){
  border-radius: 20px;
}
:deep(.p-listbox-header){
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

@media (max-width: 1280px) and (min-width: 501px) {
    .VehiclesListContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .VehiclesListContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 1279px) {
    .VehiclesListContainer{
      padding: 5px;
      border-radius: 20px;
      margin: 10px;
      max-width: 100%;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 10px;
      display: block;
    }
    .containerFooter{
      margin: 20px;
    }
    :deep(.p-inputtext){
      width: 100%;
      height: 60px;
    }
    :deep(.p-dropdown){
      width: 300px;
      height: 60px;
      line-height: 36px;
    }
  }

</style>