<template>
    <div class="cardContainer">
        <h1>Tempo di sicurezza</h1>
        <p>Se il tempo calcolato da AmbuBook non soddisfa i tuoi requisiti o hai bisogno di più tempo per le movimentazioni, puoi aggiungere un margine qui</p>
        <br>
        <label for="safetyTime" class="block mb-2">Tempo di sicurezza</label>
        <InputNumber ref="safetyTime" v-model="safetyTime" inputId="safetyTime" showButtons buttonLayout="horizontal" :min="0" :max="60" :step="1" suffix=" minuti">
            <template #incrementbuttonicon>
            <span class="pi pi-plus" />
            </template>
            <template #decrementbuttonicon>
            <span class="pi pi-minus" />
            </template>
        </InputNumber>
        <br>
        <br>
        <div class="containerFooter"> 
          <span>
            <Button label="Salva" @click="SaveData()" rounded />
          </span>
        </div>
    </div>
</template>

<script>
import API from '@/api/API';
import Cookies from 'js-cookie';

export default {
  name: 'CompanySafetyTime',
  components: {
  },
  mounted() {
    this.reloadSafetyTime();
  },  
  data() {
    return {
      safetyTime: 0
    }
  },
  methods: {
    SaveData() {
      const jwt = Cookies.get('token');
      const companyId = Cookies.get('companyId');
      if (jwt === undefined || companyId === undefined) {
        this.$router.push({ name: 'login ' });
      }

      const obj = {
        safetyTime: this.safetyTime
      }

      API.CompanyAPI.UpdateCompanySafetyTime(companyId, jwt, obj).then(() => {
        this.$toast.add({severity:'info', summary: 'Successo', detail: 'Tempo di sicurezza aggiornato', life: 3000});
      }).catch(() => {
        this.$toast.add({ severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio del tempo di sicurezza' });
      });
    },
    reloadSafetyTime() {
      const jwt = Cookies.get('token');
      const companyId = Cookies.get('companyId');
      API.CompanyAPI.GetCompanySafetyTime(companyId, jwt).then(response => {
        this.safetyTime = response.data;
      }).catch(error => {
        console.log(error);
      });
    }
  }
}

</script>

<style scoped>
.cardContainer{
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 100%;
    position: relative;
    /* margin: 200px auto; */
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}

.containerFooter{
  margin-top: 25px;
}
@media (max-width: 1900px){
  .cardContainer{
      padding:20px;
      padding-top: 70px;
      padding-bottom: 35px;
    }
}

@media (max-width: 500px){
    .cardContainer{
      padding: 20px;
      border-radius: 20px;
      margin: 10px;
    }
}
</style>