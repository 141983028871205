<template>
  <div :class=getClassObject()>
    <div class="containerReducedMode" v-if="reducedMode">
      <Button icon="pi pi-search" rounded aria-label="Filter" @click="MaximizeAndMinimizeSearchBox" />
      <div class="addressContainer" v-if="formattedStartAddress && formattedArriveAddress">
        <h5>{{formatAddress(formattedStartAddress)}}</h5>
        &nbsp;&nbsp;
        <i v-bind:class="TypeOption == 2 ? 'pi pi-arrow-right-arrow-left' : 'pi pi-arrow-right'"></i>
        &nbsp;&nbsp;
        <h5>{{formatAddress(formattedArriveAddress)}}</h5>
      </div>
    </div>
    <div class="containerHeader" v-if="!reducedMode"> 
      <Button v-if="_showClosingButton" icon="pi pi-times" small rounded aria-label="Filter" @click="MaximizeAndMinimizeSearchBox" />
    </div>
    <div class="containerContent">
      <span class="p-float-label" v-if="!reducedMode">
        <Dropdown  v-model="VehicleOption" inputId="VehicleOption" :options="VehicleOptions" optionLabel="name" optionValue="code"/>
        <label for="VehicleOption">Tipo mezzo</label>
      </span>

      <span class="p-float-label" v-if="!reducedMode">
        <Dropdown  v-model="TypeOption" inputId="TypeOption" @change="serviceTypeChanged" :options="TypeOptions" optionLabel="name" optionValue="code"/>
        <label for="TypeOption">Tipo servizio</label> 
      </span>

      <span class="p-float-label p-input-icon-right" v-if="!reducedMode">
        <Calendar v-model="ServiceDate" inputId="serviceDate" dateFormat="dd/mm/yy" :minDate="new Date()" showIcon iconDisplay="input"/>
        <label for="serviceDate">{{labelDate}}</label>
      </span>

      <span class="p-float-label p-input-icon-right" v-if="!reducedMode">
        <Calendar id="serviceHour" v-model="ServiceHour" showIcon iconDisplay="input" timeOnly stepMinute="5">
          <template #inputicon="{ clickCallback }">
            <InputIcon class="pi pi-clock cursor-pointer" @click="clickCallback" />
          </template>
        </Calendar>
        <label for="serviceHour">{{labelHour}}</label>
      </span>
    </div>
    <div class="secondRowContainer" v-if="!reducedMode">

      <vue-google-autocomplete
      ref="startAddress"
      id="googleStartAddress"
      :fields="['address_components', 'formatted_address', 'geometry', 'name']"
      classname="form-control"
      placeholder="Partenza"
      v-on:placechanged="getStartAddressData"
      v-on:no-results-found="getStartAddressData"
      country="it"
      types=""
      class="googleAutocomplete"/>

      <vue-google-autocomplete
      ref="arriveAddress"
      id="googleArriveAddress"
      :fields="['address_components', 'formatted_address', 'geometry', 'name']"
      classname="form-control"
      placeholder="Destinazione"
      v-on:placechanged="getArriveAddressData"
      v-on:no-results-found="getArriveAddressData"
      country="it"
      types=""
      class="googleAutocomplete"/>
    </div>

    <div class="advancedSearchContainer" v-show="showAdvancedSearch && !reducedMode">
      <div class="advancedSearchFirstRow"> 
        <div class="searchRadiusContainer">
          <p>Raggio di ricerca (Km)</p>
          <InputText v-model.number="SearchKilometersRadius" class="w-full" readonly />
          <Slider v-model="SearchKilometersRadius" :min="10" :max="500" class="w-full" id="radiusSlider" />
        </div>
        <div class="OneTripContainer"> 
          <div class="waitingTimeInput">
            <label for="waitingTimeInput" class="block mb-2 labelWaitingTime"> Tempo di attesa </label>
            <InputNumber ref="waitingTimeInput" :disabled="!ServiceWithWaiting" v-model="WaitingTime" inputId="waitingTimeInput" showButtons buttonLayout="horizontal" :min="30" :max="180" :step="30" suffix=" minuti">
              <template #incrementbuttonicon>
                <span class="pi pi-plus" />
              </template>
              <template #decrementbuttonicon>
                <span class="pi pi-minus" />
              </template>
            </InputNumber>
          </div>
        </div>
      </div>
    </div>
    <div class="advancedSearchBottomToggle" @click="toggleAdvancedSearch" v-if="!reducedMode">
        <p>Ricerca avanzata</p>
        <i :class="showAdvancedSearch ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" />
    </div>
    <span class="searchButtonContainer" v-if="!reducedMode"> 
      <Button label="Cerca" rounded @click="triggerSearch" />
    </span>
    <div class="containerFooter" v-if="!reducedMode"></div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>   
</template>

<script>
import Helper from '@/helper/Helpers';
import { useConfirm } from "primevue/useconfirm";
import { ref } from 'vue';

export default {
  name: 'SearchBox',
  props: [
    'showClosingButton', 
    'mobileMode', 
    'start',
    'destination',
    'searchObject'
  ],
  components: {
  },
  data(){
    return {
        _showClosingButton: this.showClosingButton,
        reducedMode: this.mobileMode,
        showAdvancedSearch: false,
        _searchObject: this.searchObject,
        isOnMobileDisplay: window.innerWidth < 1280,

        startAddress: null,
        formattedStartAddress: this.start,
        arriveAddress: null,
        formattedArriveAddress: this.destination,

        VehicleOptions: [
          { name: 'Ambulanza', code: 1 },
          { name: 'Auto', code: 2 },
          { name: 'Trasporto disabili', code: 3 },
        ],

        TypeOptions: [
          { name: 'Trasporto', code: 1 },
          { name: 'Visita', code: 2 },
          { name: 'Dimissioni', code: 3 },
        ],

        VehicleOption: null,
        TypeOption: null,
        ServiceDate: null,
        ServiceHour: null,
        SearchKilometersRadius: 200,
        ServiceWithWaiting: false,
        WaitingTime: 60,
        focusOnWaiting: false,

        labelDate: 'Data prenotazione',
        labelHour: 'Ora prenotazione',

        resultSearch: [],
        showResults: false,

        confirmWating: useConfirm(),

        PopupWaiting: () => {
          this.confirmWating.require({
              message: `Hai selezionato ${Helper.TimeHelper.getFormattedTimeFromMinutes(this.WaitingTime)}
                        di attesa, sei sicuro di voler procedere?`,
              header: 'Vuoi procedere?',
              icon: 'pi pi-exclamation-triangle',
              rejectClass: 'p-button-secondary p-button-outlined',
              rejectLabel: 'No',
              acceptLabel: 'Si',
              accept: () => {
                  this.executeSearch();
              },
              reject: () => {
                  this.showAdvancedSearch = true;
              }
          });
        },

        isEmpty: (value) => value === null || value === undefined || value === "",

        isValidOption: (option, min, max) => option != null && option >= min && option <= max,

        showErrorToast: (message) => {
          this.$refs.toast.add({
            severity: 'error',
            summary: 'Attenzione',
            detail: message,
            life: 3500
          });

          return false;
        }
      }
  },
  mounted() {
    if(this._searchObject != null) {
      // Se sono in modalià mobile, non posso settare i due valori
      // causa: le input non sono ancora renderizzate
      if (this.$refs.startAddress != undefined && this.$refs.arriveAddress != undefined) {
        this.$refs.startAddress.update(this._searchObject.fromLocation.address);
        this.$refs.arriveAddress.update(this._searchObject.toLocation.address);
      }

      this.VehicleOption = this._searchObject.vehicleType;
      this.TypeOption = this._searchObject.serviceType;
      this.ServiceDate = Helper.TimeHelper.getDateToUTC(new Date(this._searchObject.dateTime));
      this.ServiceHour = Helper.TimeHelper.getDateToUTC(new Date(this._searchObject.dateTime));
      this.SearchKilometersRadius = this._searchObject.maxRadius;
      this.ServiceWithWaiting = this._searchObject.serviceType == 2 ? true : false;
      this.OnlyOneTrip = this._searchObject.serviceType == 1 ? true : false;
      this.WaitingTime = this._searchObject.waitingTime;
    }
  },
  watch: {
    
  },
  updated: function () {
    this.$nextTick(function () {
      if (this._searchObject != null && this.$refs.startAddress != undefined && this.$refs.arriveAddress != undefined) {
        this.$refs.startAddress.update(this._searchObject.fromLocation.address);

        this.$refs.arriveAddress.update(this._searchObject.toLocation.address);
      }
    });
  },
  methods: {
    getClassObject() {
      return {
        'searchBoxContainer': true,
        'searchBoxContainerReduced': this.reducedMode,
      };
    },
    MaximizeAndMinimizeSearchBox(){
      this.reducedMode = !this.reducedMode;
    },
    getArriveAddressData: function (addressData, placeResultData, id) {
      if (placeResultData === "googleArriveAddress") {
        this.arriveAddress = null;
      } else {
        this.arriveAddress = addressData;
        if (placeResultData.formatted_address.includes(placeResultData.name)) {
          placeResultData.formatted_address = placeResultData.formatted_address;
        } else {
          placeResultData.formatted_address = placeResultData.name + ', ' + placeResultData.formatted_address;
        }
        this.formattedArriveAddress = placeResultData.formatted_address;
      }
    },

    getStartAddressData: function (addressData, placeResultData, id) {
      if (placeResultData === "googleStartAddress")  {
        this.startAddress = null;
      } else {
        this.startAddress = addressData;
        if (placeResultData.formatted_address.includes(placeResultData.name)) {
          placeResultData.formatted_address = placeResultData.formatted_address;
        } else {
          placeResultData.formatted_address = placeResultData.name + ', ' + placeResultData.formatted_address;
        }
        this.formattedStartAddress = placeResultData.formatted_address;
      }
    },

    serviceTypeChanged() {
      if (this.TypeOption == 1) {
        this.ServiceWithWaiting = false;
        this.labelDate = 'Data trasporto';
        this.labelHour = 'Ora destinazione';
      } else if (this.TypeOption == 2) {
        this.ServiceWithWaiting = true;
        this.showAdvancedSearch = true;
        this.labelDate = 'Data visita';
        this.labelHour = 'Ora visita';
        this.$nextTick(() => {
          this.$refs.waitingTimeInput.$el.querySelector('span').focus();
        });
      } else if (this.TypeOption == 3) {
        this.ServiceWithWaiting = false;
        this.labelDate = 'Data dimissioni';
        this.labelHour = 'Ora dimissioni';
      }
    },
    toggleAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    triggerSearch() {
      if (this.isValidSearch() === false) {
        return;
      }

      if(this.ServiceWithWaiting){
        this.PopupWaiting();
      }
      else{
        this.executeSearch();
      }
    },
    executeSearch() {
      let formattedDate = Helper.TimeHelper.getFormattedDate(this.ServiceDate);
      let searchDate = Helper.TimeHelper.getFormattedDateISO(new Date(formattedDate.toISOString().slice(0, 10) + 'T' + this.ServiceHour.toISOString().slice(11, 17) + '00Z'));
      let searchObj = {};

      if (this._searchObject && this._searchObject.fromLocation != undefined) {
        if (this.startAddress != null && this._searchObject.fromLocation.address != this.startAddress.address) {
          searchObj.fromLocation = {
            address: this.formattedStartAddress,
            lon: this.startAddress.longitude,
            lat: this.startAddress.latitude,
          };
        } else {
          searchObj.fromLocation = {
            address: this._searchObject.fromLocation.address,
            lon: this._searchObject.fromLocation.lon,
            lat: this._searchObject.fromLocation.lat,
          };
        }
      } else {
        searchObj.fromLocation = {
          address: this.formattedStartAddress,
          lon: this.startAddress.longitude,
          lat: this.startAddress.latitude,
        };
      }

      if (this._searchObject && this._searchObject.toLocation != undefined) {
        if (this.arriveAddress != null && this._searchObject.toLocation.address != this.arriveAddress.address) {
          searchObj.toLocation = {
            address: this.formattedArriveAddress,
            lon: this.arriveAddress.longitude,
            lat: this.arriveAddress.latitude,
          };
        } else {
          searchObj.toLocation = {
            address: this._searchObject.toLocation.address,
            lon: this._searchObject.toLocation.lon,
            lat: this._searchObject.toLocation.lat,
          };
        }
      } else {
        searchObj.toLocation = {
          address: this.formattedArriveAddress,
          lon: this.arriveAddress.longitude,
          lat: this.arriveAddress.latitude,
        };
      }

      searchObj.vehicleType = this.VehicleOption;
      searchObj.waitingTime = this.WaitingTime;
      searchObj.dateTime = searchDate;
      searchObj.maxRadius = this.SearchKilometersRadius;
      searchObj.serviceType = this.TypeOption;

      if (searchObj != null) 
      {
        this.$router.push({ name: 'results', query: {
            fromLocation: JSON.stringify(searchObj.fromLocation),
            toLocation: JSON.stringify(searchObj.toLocation),
            vehicleType: searchObj.vehicleType,
            dateTime: searchObj.dateTime,
            maxRadius: searchObj.maxRadius,
            serviceType: searchObj.serviceType,   
            waitingTime: searchObj.waitingTime,
          } 
        }).then(() => {
          this.$router.go();
        });
      }
    },

    isValidSearch() {
      console.log(this.ServiceHour);
      if (!this.isValidOption(this.VehicleOption, 1, 3)) {
        return this.showErrorToast('Seleziona un tipo di veicolo.');
      } else if (!this.isValidOption(this.TypeOption, 1, 3)) {
        return this.showErrorToast('Seleziona un tipo di servizio.');
      } else if (this.ServiceDate === null) {
        return this.showErrorToast('Inserisci una data.');
      } else if (this.ServiceHour === null) {
        return this.showErrorToast('Inserisci un orario.');
      } else if (Helper.TimeHelper.isToday(this.ServiceDate) && Helper.TimeHelper.isTimePlusOneHourLessThanNow(this.ServiceHour)) {
        return this.showErrorToast('L\'orario di prenotazione è troppo vicino a quello attuale.');
      } else if ((this.isEmpty(this.startAddress) && (!this._searchObject || this.isEmpty(this._searchObject.fromLocation)))) {
        return this.showErrorToast('Inserisci un indirizzo di partenza.');
      } else if ((this.isEmpty(this.arriveAddress) && (!this._searchObject || this.isEmpty(this._searchObject.toLocation)))) {
        return this.showErrorToast('Inserisci un indirizzo di arrivo.');
      } else if ((!this._searchObject || this._searchObject.toLocation === undefined || this._searchObject.toLocation.address !== this.arriveAddress) && 
          this.arriveAddress != null && this.arriveAddress.street_number === undefined) {
        return this.showErrorToast('Inserire numero civico nell\'indirizzo di arrivo');
      } else if ((!this._searchObject || this._searchObject.fromLocation === undefined || this._searchObject.fromLocation.address !== this.startAddress) &&
          this.startAddress != null && this.startAddress.street_number === undefined) {
        return this.showErrorToast('Inserire numero civico nell\'indirizzo di partenza');
      }
      return true;
    },
    formatAddress(address) {
      let t = Helper.GeoHelper.removeCAPandCountry(address);
      return Helper.GeoHelper.trimLongAddress(t);
    },
  }
}
</script>


<style scoped>
:deep(.p-inputnumber-buttons-horizontal .p-inputnumber-input) {
  width: 110px;
  height:30px;
}
:deep(.p-inputnumber-buttons-horizontal .p-inputnumber-input):focus {
  box-shadow: none !important;
}
:deep(.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up) {
  height:30px !important;
}
:deep(.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down) {
  height:30px !important;
}
.labelWaitingTime{
  color: gray;
  margin: 10px;
}
.waitingTimeInput{
  display: flex;
  position: relative;
  align-items: baseline;
  margin-left: 15px;
}
.googleAutocomplete{
  font-family: var(--font-family);
  /*font-feature-settings: var(--font-feature-settings, normal);*/
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
  width: 50%;
  margin: 5px;
}

.googleAutocomplete:hover{
  border-color: #EF8354;
}
:deep(.googleAutocomplete:focus){
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
  outline: none;
  border-color: #EF8354;
}
:deep(vue-google-autocomplete:focus){
  border: #EF8354;
}
.searchBoxContainer{
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
  position: relative;
  margin: 200px auto;
  margin-top: 50px;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  background-color: white;
  width: fit-content;
  max-width: 850px;
}
.searchBoxContainerReduced{
  margin-bottom: 0px;
  width: -webkit-fill-available;
  border-radius: 200px;
  padding: 10px;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
}
.containerContent{
  /*display: -webkit-inline-box;*/
  display: -webkit-flex;
}

:deep(.p-dropdown){
  width: 180px;
  text-align: left;
}
.searchBoxContainer .p-float-label{
  margin: 5px;
  width: max-content;
}
.searchBoxContainer span button{
  margin: 5px;
  width: -webkit-fill-available;
}
.searchBoxContainerReduced .p-float-label{
  margin: 5px;
  width: max-content;
}
.searchBoxContainerReduced span button{
  margin: 5px;
  width: -webkit-fill-available;
}
:deep(.p-button:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}
.secondRowContainer{
  display: flex;
}
.searchButtonContainer{
  margin-top: 20px;
}
.advancedSearchBottomToggle{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #747474;
}
.advancedSearchBottomToggle i, .advancedSearchBottomToggle p{
  margin: 10px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.advancedSearchBottomToggle i{
  font-size: small;
}
.searchRadiusContainer{
  margin: 5px;
  color: gray;
}
#radiusSlider{
  margin-top: 15px;
}
.OneTripContainer{
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
  margin-top: 15px;
}
.OneTripContainer p{
  margin: 10px;
}
.containerHeader{
  /* margin-bottom: 40px; */
}

.containerReducedMode{
  display: flex;
  align-items: center;
}
.containerReducedMode p{
  margin-left: 10px;
}

@media (max-width: 925px){
  .OneTripContainer{
    display: block;
  }
  .OneTripContainer p{
    margin-top: 30px;
  }
  .waitingTimeInput{
    display: block;
    position: relative;
    align-items: baseline;
    margin: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  :deep(.p-inputnumber-buttons-horizontal .p-inputnumber-input) {
    width: 200px;
    height: 60px;
    margin: 0px;
  }
  :deep(.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up) {
    height:auto !important;
    margin: 0px;
  }
  :deep(.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down) {
    height:auto !important;
    margin:0px;
  }
  :deep(.p-inputnumber-buttons-horizontal .p-inputnumber-input):focus {
    box-shadow: none !important;
  }
  :deep(.p-inputnumber-buttons-horizontal) {
    height:60px;
  }
  :deep(.p-inputtext), :deep(.p-dropdown) {
    width: 300px;
    height: 60px;
    line-height: 36px;
  }
  .searchBoxContainer{
    margin-top: 10px;
    margin-bottom: 50px;
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .searchBoxContainerReduced{
    margin: 5px;
    margin-bottom: 0px;
    padding: 10px;
  }
  .googleAutocomplete{
    max-width: 300px;
    height: 60px;
    margin: 15px;
    width: -webkit-fill-available;
  }
  .containerContent{
    display: grid;
    justify-content: center;
  }
  .secondRowContainer{
    display:initial;
  }
  .searchBoxContainer .p-float-label{
    margin: 15px;
  }
  .advancedSearchBottomToggle{
    margin-bottom: 15px;
  }
  .searchRadiusContainer{
    max-width: 300px;
    margin: 5px auto;
  }

  .addressContainer{
    left: 5px;
    display: flex;
    position: relative;
    align-items: center;
    height: 5px;
  }
}
</style>