<template>
  <div class="PatientFormContainer">
    <div class="containerHeader"> 
      <i class="pi pi-user-edit" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>Dati paziente</h1>
      <p>Per concludere la tua prenotazione, abbiamo bisogno di alcuni dati della persona trasportata.</p>
    </div>

    <div class="containerContent">
      <template v-for="(field, index) in CustomerFormFields" v-if=" this.pageIndex==0">
        <span v-if="(field.type === 'inputnumber' && showAdvancedData) || field.type != 'inputnumber'" :key="index" class="p-float-label p-input-icon-right">
          <IconField iconPosition="right">
            <InputIcon :class="field.iconClass"></InputIcon>
            <template v-if="field.type === 'inputtext'">
              <InputText v-if="field.id != 'TaxCode'" v-model="$data[field.id]" :loading="loading"/>
              <InputText v-if="field.id == 'TaxCode'" v-model="$data[field.id]" @input="transformToUppercase($event)" :loading="loading"/>
            </template>
            <template v-else-if="field.type === 'calendar'">
              <Calendar v-model="$data[field.id]" :loading="loading" dateFormat="dd/mm/yy" showIcon iconDisplay="input" :max-date="new Date()"/>
            </template>
            <template v-else-if="field.type === 'dropdown'">
              <Dropdown v-model="$data[field.id]" :options="$data[field.sexOptions]" optionLabel="name" optionValue="code"/>
            </template>
            <template v-else-if="field.type === 'inputnumber'">
              <InputNumber v-model="$data[field.id]" mode="decimal" showButtons :min="field.min" :max="field.max" :suffix="field.suffix" />
            </template>
          </IconField>
          <label v-if="(field.type === 'inputnumber' && showAdvancedData) || field.type != 'inputnumber'" :for="field.id">{{ field.label }}</label>
        </span>
      </template>

      <template v-for="(request, index) in PatientRequests" :key="index">
        <div v-if="request.pageId === pageIndex" class="requestContainer">
          <div class="requestTitle">
            <h3>{{ request.label }}</h3>
          </div>
          <div class="requestDescription">
            <p>{{ request.description }}</p>
          </div>
          <div class="requestResponse">
            <SelectButton :modelValue="getModelValue(request.model)" :options="request.options" aria-labelledby="basic" @update:modelValue="setModelValue(request.model, $event)" @click="nextQuestion"/>
          </div>
        </div>
      </template>
      <div v-if="isExamination">
        <div class="Notes" v-if="this.pageIndex == 0">
          <FloatLabel>
            <Textarea v-model="TypeOfExamination" rows="2" cols="30" inputId="typeOfExamination" autoResize/>
            <label for="typeOfExamination">Tipo di visita</label>
          </FloatLabel>
        </div>
      </div>
      <div class="Notes" v-if="this.pageIndex == 0">
        <FloatLabel>
        <Textarea v-model="Notes" rows="3" cols="30" inputId="notes" autoResize/>
        <label for="notes">Note</label>
      </FloatLabel>
      </div>
      <p style="color: darkgrey; font-size: x-small;" v-if="this.pageIndex == 0">I dati contrassegnati dall'asterisco (*) sono obbligatori per la prenotazione.</p> 
    </div>
    <div class="containerFooter"> 
      <div class="containerReturnBackButton" @click="managePageChange(false)" v-if="this.pageIndex != 0"> 
        <span> 
          <i class="pi pi-arrow-left"></i>
        </span>
        <span> 
          <p>Indietro</p>
        </span>
      </div>
      <span v-if="this.pageIndex != 7">
        <Button label="Avanti" :loading="loading" @click="managePageChange(true)" rounded />
      </span>
      <span v-if="this.pageIndex == 7">
        <Button label="Invia prenotazione" :loading="loading" @click="bookThis" rounded />
      </span>
    </div>
    <br>
    <br>
    <small class="disclaimer-google">Questo sito è protetto da reCAPTCHA. <br>
      Si applicano 
      <a style="text-decoration: none; color: #EF8354;" href="https://policies.google.com/privacy">Privacy Policy</a> e
      <a style="text-decoration: none; color: #EF8354;" href="https://policies.google.com/terms">Termini di servizio</a> di Google.
    </small>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import { useConfirm } from 'primevue/useconfirm';
import Helper from '@/helper/Helpers';

export default {
  name: 'PatientForm',
  props: {
    isExamination: {
      type: Boolean,
      default: false
    }
  },
  data(){
      return{
        //DEBUG
        debug: false,

        //Generic Variables
        loading: false,
        showAdvancedData:true,
        
        //Index that recognizes the page of the requestes
        pageIndex: 0,

        //Request List
        PatientRequests: [
          { pageId: 1, label: 'È presente un accompagnatore?', description:'Il fornitore del servizio ha necessità di capire se al momento del servizio sarà presente un accompagnatore per motivi organizzativi.',  iconClass:'pi pi-user', model: 'IsCompanionPresent', options: ["Si","No"] },
          { pageId: 2, label: 'Il paziente può camminare?', description:'Nel caso il paziente non potesse camminare, durante il servizio verranno adoperati gli strumenti necessari al suo spostamento in sicurezza.',  iconClass:'pi pi-user', model: 'CantWalk', options: ["Si","No"] },
          { pageId: 3, label: 'Il paziente ha bisogno di ossigeno?', description:'Nel caso il paziente avesse problemi respiratori e necessitasse di ossigeno supplementare, durante il servizio verranno adoperati tutti gli strumenti necessari.',  iconClass:'pi pi-user', model: 'NeedOxygen', options: ["Si","No"] },
          { pageId: 4, label: 'È presente un ascensore?', description:'Indicare se è presente un ascensore può aiutare gli operatori durante il servizio.',  iconClass:'pi pi-user', model: 'Lifter', options: ["Si","No"] },
          { pageId: 5, label: 'Il paziente può stare seduto?', description:'Se il paziente non potesse stare seduto, gli operatori dovranno adoperare degli strumenti dedicati.',  iconClass:'pi pi-user', model: 'CanSit', options: ["Si","No"] },
          { pageId: 6, label: 'Legge 104/92?', description:'Il paziente è tutelato dalla Legge 104/1992?',  iconClass:'pi pi-user', model: 'Law104', options: ["Si","No"] },
          { pageId: 7, label: 'Assicurazione?', description:'Il paziente o il prenotante dispone di un\'assicurazione che copre il trasporto in ambulanza?',  iconClass:'pi pi-user', model: 'Insurance', options: ["Si","No"] },
        ],

        //Patient Variables
        FirstName: "",
        LastName:"",
        TaxCode: "",
        Sex: "",
        BirthDate:"",
        Weight: null,
        CantWalk: undefined,
        NeedStretcher: undefined,
        NeedOxygen: undefined,
        Law104: undefined,
        Insurance: undefined,
        VisitFloor: null,
        PhoneNumberReference: null,
        Lifter: undefined,
        CanSit: undefined,
        IsCompanionPresent: undefined,
        Notes: "",
        TypeOfExamination: "",

        confirmForm: useConfirm(),

        //Input Dynamic Creation
        CustomerFormFields: [
          { id: 'FirstName', label: '*Nome', type: 'inputtext', iconClass:'pi pi-user' },
          { id: 'LastName', label: '*Cognome', type: 'inputtext', iconClass:'pi pi-user' },
          { id: 'TaxCode', label: '*Codice fiscale', type: 'inputtext', iconClass:'pi pi-id-card' },
          { id: 'Sex', label: '*Sesso', type: 'dropdown', iconClass:'pi pi-user', sexOptions:'SexOptions' },
          { id: 'BirthDate', label: '*Data di nascita', type: 'calendar' },
          { id: 'PhoneNumberReference', label: 'Numero di telefono riferimento', type: 'inputtext', iconClass:'pi pi-phone' },
          { id: 'Weight', label: '*Peso', type: 'inputnumber', suffix: ' Kg', min: 0, max:350},
          { id: 'VisitFloor', label: 'N° Piano abitazione', type: 'inputnumber', suffix: '', min: -1, max:100 },
        ],

        //Sex selector component Constructor
        SexOptions: [
          { name: 'Uomo', code: 1 },
          { name: 'Donna', code: 2 },
        ],
      }
  },
  methods: {

    onTaxCodeInput(event) {
      const taxCodeRegex = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
      const taxCode = this.TaxCode;
      if(taxCodeRegex.test(taxCode)){
        var birthDate = Helper.UsersHelper.getBirthDate(taxCode);
        var gender = Helper.UsersHelper.getGender(taxCode);
        this.BirthDate = birthDate;
        this.Sex = gender;
      }
      else{
        this.BirthDate = undefined;
        this.Sex = undefined;
      }
    },

    getModelValue(model) {
      return this[model];
    },

    setModelValue(model, value) {
      this[model] = value;
    },

    toggleAdvancedData() {
      this.showAdvancedData = !this.showAdvancedData;
    },

    nextQuestion() {
      if (this.pageIndex === 7) {
        return;
      }
      this.pageIndex++;
    },

    managePageChange(increase) {
      if (this.pageIndex === 0) {
        if (increase) {
          if(this.isValid()){
            if (this.Notes === "") {
              this.confirmForm.require({
                message: `Non hai inserito alcuna nota. Vuoi procedere ugualmente?`,
                header: 'Attenzione',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'No',
                acceptLabel: 'Si',
                accept: () => {
                  this.pageIndex++;
                },
                reject: () => {
                  //Rimane nella stessa pagina
                }
              });
            } else {
              this.pageIndex++;
            }
          }
          else{
            return;
          }
        }
        return;
      }

      const currentRequest = this.PatientRequests.find(request => request.pageId === this.pageIndex);

      if (increase) {
        if (this[currentRequest.model] !== undefined && this[currentRequest.model] !== null && this[currentRequest.model] !== "") {
          if (this.pageIndex < this.PatientRequests.length) {
            this.pageIndex++;
          }
        } else {
          this.$refs.toast.add({ severity: 'warn', summary: 'Attenzione!', detail: 'Per procedere è necessario rispondere alla domanda corrente',life: 5000 });
        }
      } else {
        if (this.pageIndex != 0) {
          this.pageIndex--;
        }
      }
    },

    isValid() {
      const taxCodeRegex = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;

      if (this.FirstName === "" || this.LastName === "") {
        this.$refs.toast.add({ severity: 'error', summary: 'Errore', detail: 'Inserire nome e cognome' });
        return false;
      } else if (!taxCodeRegex.test(this.TaxCode)) {
        this.$refs.toast.add({ severity: 'error', summary: 'Errore', detail: 'Inserire un codice fiscale valido' });
        return false;
      } else if (this.Sex === "") {
        this.$refs.toast.add({ severity: 'error', summary: 'Errore', detail: 'Inserire il sesso' });
        return false;
      } else if (this.BirthDate === null) {
        this.$refs.toast.add({ severity: 'error', summary: 'Errore', detail: 'Inserire la data di nascita' });
        return false;
      } else if (this.Weight === null) {
        this.$refs.toast.add({ severity: 'error', summary: 'Errore', detail: 'Inserire il peso' });
        return false;
      }

      return true;
    },

    transformToUppercase(event) {
      this.TaxCode = this.TaxCode.toUpperCase();
      this.onTaxCodeInput(event);
    },

    bookThis() {
      if (!this.isValid()) return;
      const currentRequest = this.PatientRequests.find(request => request.pageId === this.pageIndex);
      if (this[currentRequest.model] !== undefined && this[currentRequest.model] !== null && this[currentRequest.model] !== "") {
          this.confirmBooking();
        } else {
          this.$refs.toast.add({ severity: 'warn', summary: 'Attenzione!', detail: 'Per procedere è necessario rispondere alla domanda corrente',life: 5000 });
        }
    },

    confirmBooking() {
      setTimeout(function() {
        this.confirmForm.require({
          message: `La prenotazione verrà inviata. Confermi?`,
          header: 'Conferma',
          icon: 'pi pi-exclamation-triangle',
          rejectClass: 'p-button-secondary p-button-outlined',
          rejectLabel: 'No',
          acceptLabel: 'Si',
          accept: () => {
            this.emitBooking();
          },
          reject: () => {
            this.showAdvancedData = true;
          }
        });
      }.bind(this), 100);
    },

    emitBooking() {
      this.$emit('booking', {
        FirstName: this.FirstName,
        LastName: this.LastName,
        Sex: this.Sex,
        BirthDate: this.BirthDate,
        Weight: this.Weight,
        TaxCode: this.TaxCode,
        Law104: this.convertToBoolean(this.Law104),
        Insurance: this.convertToBoolean(this.Insurance),
        CantWalk: this.convertToBoolean(this.CantWalk),
        NeedStretcher: this.convertToBoolean(this.NeedStretcher),
        NeedOxygen: this.convertToBoolean(this.NeedOxygen),
        VisitFloor: this.VisitFloor,
        Notes: this.Notes,
        PhoneNumberReference: this.PhoneNumberReference,
        Lifter: this.convertToBoolean(this.Lifter),
        CanSit: this.convertToBoolean(this.CanSit),
        TypeOfExamination: this.TypeOfExamination,
        IsCompanionPresent: this.convertToBoolean(this.IsCompanionPresent),
      });
    },

    convertToBoolean(value) {
      return value === "Si" ? true : value === "No" ? false : value;
    }
  },
  watch: {
    CantWalk(newValue) {
      if (newValue) {
        this.NeedStretcher = false;
      }
    },
    NeedStretcher(newValue) {
      if (newValue) {
        this.CantWalk = false;
      }
    }
  }
}
</script>

<style scoped>
.requestContainer{
  position: relative;
  height: 42.3rem;
  align-content: center;
}
.requestDescription{
  color: #747474;
}
.requestResponse{
  margin-top: 50px;
  margin-bottom: 50px;
}
:deep(.p-button-group .p-button){
  width: 40%;
}
.PatientFormContainer{
  padding: 70px;
  border-radius: 80px;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
  max-width: 500px;
  position: relative;
  margin: 50px auto;
  margin-top: 50px;
  position: relative;
  height: fit-content;
  text-align: center;
  background-color: white;
  height: auto;
}
.PatientFormContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerHeader{
  margin-bottom: 25px;
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  margin: 15px;
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  min-width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.containerReturnBackButton{
  margin-right: 20px;
}
.containerReturnBackButton span i{
  font-size: 0.7rem; 
  color: grey; 
  margin-top: 20px;
  margin-right: 10px;
  transition: 0.5s;
}
.containerReturnBackButton span i:hover{
  cursor: pointer;
}
.containerReturnBackButton{
  position: relative;
  display: flex;
  display: inline-flex;
  color: grey;
  transition: 0.5s;
  font-size: 1rem;
  align-items: baseline;
}
.containerReturnBackButton:hover{
  cursor: pointer;
}
.align-items-center{
  color: grey;
  max-width: 300px;
  margin: 15px;
}
:deep(.p-checkbox){
  margin-right: 10px !important;
}
:deep(.p-dropdown){
  width: 300px;
  text-align: left;
}
:deep(.p-dropdown .p-dropdown-label:focus){
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
}
:deep(.p-inputnumber){
  max-width: 300px;
}
.advancedSearchBottomToggle{
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: #747474;
}
.advancedSearchBottomToggle i, .advancedSearchBottomToggle p{
  margin: 10px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.advancedSearchBottomToggle i{
  font-size: small;
}
.disclaimer-google{
  font-size: 0.8rem;
  color: grey;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 1280px) and (min-width: 501px) {
    .PatientFormContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .PatientFormContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px) {
    .requestContainer{
      height: auto;
    }
    .PatientFormContainer{
      padding: 10px;
      padding-bottom: 30px;
      border-radius: 20px;
      margin: 10px;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 0px;
      margin-top: 10px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    .containerReturnBackButton{
      margin-bottom: 0px;
    }
    :deep(.p-dropdown){
      width: 300px;
      height: 60px;
      line-height: 36px;
    }
  }
</style>