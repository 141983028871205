<template>
  <div class="DashboardCalendar">
    <div v-if="this.noVehiclesAvailable" style="padding-top: 200px;">
      <h1 style="color: gray;">Pare che il tu non abbia ancora caricato nessun veicolo, usa il pulsante sotto per inserire il primo!</h1>
      <Button label="Inserisci il tuo primo veicolo" icon="pi pi-plus" @click="goToVehiclesForm()" rounded />
    </div>
    <p v-if="!this.noVehiclesAvailable" class="text-xl" style="text-align: left; color: gray;">Veicolo</p>
    <div v-if="!this.noVehiclesAvailable" class="flex justify-content-between flex-wrap" style="margin-bottom: 15px;">
      <span v-if="!this.noVehiclesAvailable" class="p-float-label p-input-icon-right">
        <Dropdown v-if="!this.noVehiclesAvailable" v-model="selectedVehicle" :options="vehicles" inputId="vehicleDropdown"
          optionLabel="name" placeholder="Seleziona un mezzo" class="md:w-18rem"
          @change="updateAvailabilities" />
      </span>
      <div class="flex flex-wrap gap-2">
        <Button v-if="!this.noVehiclesAvailable" severity="danger" label="Aggiungi indisponibilità" icon="pi pi-times" @click="onAddUnavailability" />
        <Button v-if="!this.noVehiclesAvailable" label="Aggiungi disponibilità" icon="pi pi-plus" @click="onAddAvailability" />
      </div>
    </div>
    <FullCalendar
      v-if="!this.noVehiclesAvailable" 
      ref="calendar"
      :options="calendarOptions"
    ></FullCalendar>
  </div>

  <Dialog v-if="!this.noVehiclesAvailable" v-model:visible="addAvailPopup" @after-hide="onRevertEventEdit" modal :header="isInserting ? 'Aggiungi disponibilità' : 'Modifica disponibilità'" :style="{ width: '25rem' }">
    <AddAvailability v-if="!withDates" :service-id="selectedVehicle.id" :company-id="companyId" @availabilityAdded="onAvailabilityAdded" :is-inserting="isInserting" />
    <AddAvailability v-if="withDates" :service-id="selectedVehicle.id" :company-id="companyId" @availabilityAdded="onAvailabilityAdded" 
    :in-start-date="inStartDate" :in-end-date="inEndDate" :availability-id="selectedAvailabilityId" :is-inserting="isInserting" :revert-event="revertEvent" />
  </Dialog>

  <Dialog v-if="!this.noVehiclesAvailable" v-model:visible="addUnavailPopup" @after-hide="onRevertEventEdit" modal :header="isInsertingUn ? 'Aggiungi indisponibilità' : 'Modifica indisponibilità'" :style="{ width: '25rem' }">
    <AddUnavailability v-if="!withDatesUn" :company-id="companyId" :service-id="selectedVehicle.id" :booking-id="externalBookingId" @unavailabilityAdded="onUnavailabilityAdded" :is-inserting="isInsertingUn" />
    <AddUnavailability v-if="withDatesUn"  :company-id="companyId" :service-id="selectedVehicle.id" :booking-id="externalBookingId" @unavailabilityAdded="onUnavailabilityAdded" 
    :in-start-date="inStartDateUn" :in-end-date="inEndDateUn" :is-inserting="isInsertingUn" :revert-event="revertEvent" :in-notes="notesUn"/>
  </Dialog>

  <Dialog v-if="!this.noVehiclesAvailable" v-model:visible="showBookingInfo" modal header="Dettagli Prenotazione" :style="{ width: '75rem' }">
      <BookingInfo :bookingId="bookingIdDetails"></BookingInfo>
  </Dialog>
</template>

<script>
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid' 
import itLocale from '@fullcalendar/core/locales/it';
import interactionPlugin from '@fullcalendar/interaction';
import API from '@/api/API';
import Cookies from 'js-cookie';
import AddAvailability from '@/components/Calendar/AddAvailability.vue';
import AddUnavailability from '@/components/Calendar/AddUnavailability.vue';
import BookingInfo from '@/components/BookingInfo.vue';
import Helper from '@/helper/Helpers';

export default {
  name: 'DashboardCalendarView',
  components: {
    AddUnavailability,
    AddAvailability,
    BookingInfo
  },
  data(){
    return{
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        selectable: true,
        editable: true,
        headerToolbar: {
          right: 'prev,next today',
          left: 'title',
        },
        initialView: 'timeGridWeek',
        timeZone: 'UTC',
        weekends: true,
        locale: itLocale,
        nowIndicator: false,
        allDaySlot: false,
        aspectRatio: 5,
        contentHeight: 'auto',
        snapDuration: "00:15:00",
        events: [],
        eventClick: function(info) {
          if (info.event._def.extendedProps.isExternalBooking !== undefined && info.event._def.extendedProps.isExternalBooking)
          {
            this.externalBookingId = info.event._def.extendedProps.bookingId;
            this.withDatesUn = true;
            this.inStartDateUn = info.event._instance.range.start;
            this.inEndDateUn = info.event._instance.range.end;
            this.isInsertingUn = false;
            this.notesUn = info.event._def.extendedProps.externalBookingNotes;
            this.addUnavailPopup = true;
          } 
          else if (info.event._def.extendedProps.bookingId !== undefined)
          {
            this.bookingIdDetails = info.event._def.extendedProps.bookingId;
            this.showBookingInfo = true;
          }
          else if (info.event._def.extendedProps.availabilityId !== undefined)
          {
            this.withDates = true;
            this.inStartDate = info.event._instance.range.start;
            this.inEndDate = info.event._instance.range.end;
            this.selectedAvailabilityId = info.event._def.extendedProps.availabilityId;
            this.isInserting = false;
            this.addAvailPopup = true;
          }
        }.bind(this),
        select: function(info) {
          this.withDates = true;
          this.inStartDate = new Date(info.startStr);
          this.inEndDate = new Date(info.endStr);
          this.isInserting = true;
          this.addAvailPopup = true;
        }.bind(this),
        eventResize: function(info) {
          this.withDates = true;
          this.revertEvent = info;
          this.inStartDate = info.event._instance.range.start;
          this.inEndDate = info.event._instance.range.end;
          this.selectedAvailabilityId = info.event._def.extendedProps.availabilityId;
          this.isInserting = false;
          this.addAvailPopup = true;
        }.bind(this),
        eventDataTransform: function(info) {
          if (info.bookingId !== undefined)
          {
            info.editable = false;
          }
        }.bind(this),
        eventDrop: function(info) {
          this.withDates = true;
          this.revertEvent = info;
          this.inStartDate = info.event._instance.range.start;
          this.inEndDate = info.event._instance.range.end;
          this.selectedAvailabilityId = info.event._def.extendedProps.availabilityId;
          this.isInserting = false;
          this.addAvailPopup = true;
        }.bind(this),
        eventDidMount: function(info) {
          if (info.event._def.extendedProps.isExternalBooking !== undefined && info.event._def.extendedProps.isExternalBooking)
          {
            info.el.style.borderRadius = "8px";
            info.el.style.borderStyle = "dotted";
            info.el.style.borderWidth = "4px";
            info.el.style.borderColor = "#c13636";
          }
          else if (info.event._def.extendedProps.bookingState !== undefined)
          {
            if (info.event._def.extendedProps.bookingState == 1)
            {
              info.el.style.borderRadius = "8px";
              info.el.style.borderStyle = "dotted";
              info.el.style.borderWidth = "4px";
              info.el.style.borderColor = "#4f5d75";
              info.el.style.backgroundColor = 'rgb(255, 112, 67, 0.5)';
            } 
            else if (info.event._def.extendedProps.bookingsState == 5)
            {
              info.el.style.borderRadius = "8px";
              info.el.style.borderStyle = "dotted";
              info.el.style.borderWidth = "4px";
              info.el.style.borderColor = "#041c46";
              info.el.style.backgroundColor = 'rgb(13, 43, 94)';
            }
          }
        }.bind(this),
        datesSet: function(info) {
          this.updateAvailabilities();
        }.bind(this)
      },
      addAvailPopup: false,
      jwt: Cookies.get('token'),
      companyId: Cookies.get('companyId'),
      vehicles: [],
      selectedVehicle: null,
      inStartDate: null,
      inEndDate: null,
      withDates: false,
      isInserting: false,
      selectedAvailabilityId: "",
      showBookingInfo: false,
      bookingIdDetails: null,
      revertEvent: null,
      noVehiclesAvailable: false,
      addUnavailPopup: false,
      isInsertingUn: false,
      withDatesUn: false,
      externalBookingId: null,
      inStartDateUn: null,
      inEndDateUn: null,
      notesUn: ""
    }
  },
  mounted() {
    if (this.jwt === undefined)
    {
      this.$router.push({name: 'login'});
      return;
    }
    
    API.ServiceAPI.GetAllServices(this.jwt, this.companyId).then((response) => {
      if (response.status === 200) {
        if(response.data != null){
          this.noVehiclesAvailable = false;
          this.vehicles = response.data.map(service => ({
            ...service
          }));
        }
        else{
          this.noVehiclesAvailable = true;
        }
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      if (this.vehicles.length > 0) {
        this.selectedVehicle = this.vehicles[0];

        this.updateAvailabilities();
      }
    });
  },
  methods: {
    goToVehiclesForm(){
      const newUrl = window.location.origin + '/dashboardvehicles';
      window.location.assign(newUrl);
    },
    onAddAvailability(){
      this.selectedAvailabilityId = null;
      this.withDates = false;
      this.inStartDate = null;
      this.inEndDate = null;
      this.isInserting = true;
      this.addAvailPopup = true;
    },
    onAddUnavailability(){
      this.withDatesUn = false;
      this.inStartDateUn = null;
      this.inEndDateUn = null;
      this.isInsertingUn = true;
      this.addUnavailPopup = true;
    },
    onAvailabilityAdded(){
      this.addAvailPopup = false;
      setTimeout(() => {
        // Added a timeout because the calendar is not updated immediately and duplicates the events
        this.updateAvailabilities();
      }, 500);
    },
    onUnavailabilityAdded(){
      this.addUnavailPopup = false;
      setTimeout(() => {
        // Added a timeout because the calendar is not updated immediately and duplicates the events
        this.updateAvailabilities();
      }, 500);
    },
    onRevertEventEdit(){
      if (this.revertEvent !== null && this.revertEvent.revert !== undefined)
        this.revertEvent.revert();
    },
    async updateAvailabilities() {
      this.calendarOptions.events = [];
      const startDate = this.$refs.calendar.calendar.view.currentStart;
      const endDate = this.$refs.calendar.calendar.view.currentEnd;

      if (this.selectedVehicle === null)
      {
        return;
      }
      
      API.AvailabilityAPI.GetAvailabilitiesForService(this.jwt, this.selectedVehicle.id, this.companyId, startDate, endDate).then((response) => {
        if (response.status === 200)
        {
          let services = response.data;
          let events = [];

          if (services === null || services.length === 0)
          {
            return;
          }

          services.forEach(availability => {
            let event = {
              title: 'Disponibile',
              start: availability.startTime,
              end: availability.endTime,
              backgroundColor: '#66BB6A',
              borderColor: '#2E7D32',
              eventOverlap: false,
              allDay: false,
              availabilityId: availability.id
            }
            events.push(event);
          });

          this.calendarOptions.events = this.calendarOptions.events.concat(events);
        }
      }).catch((error) => {
        console.log(error);
      });

      API.BookingAPI.GetBookingsCalendar(this.jwt, this.selectedVehicle.id, this.companyId, startDate, endDate).then((response) => {
        if (response.status === 200)
        {
          let bookings = response.data;
          let events = [];

          if (bookings === null || bookings.length === 0)
          {
            return;
          }

          bookings.forEach(booking => {
            let event = {};
            if (booking.externalBooking)
            {
              event = {
                title: 'Note: ' + booking.externalBookingNotes,
                start: booking.estimatedTime.companyTimeBeforeTarget,
                end: booking.estimatedTime.companyTimeAfterTarget,
                backgroundColor: '#ef4444',
                borderColor: '#c13636',
                eventOverlap: false,
                allDay: false,
                bookingId: booking.id,
                bookingState: booking.bookingState,
                isExternalBooking: true,
                externalBookingNotes: booking.externalBookingNotes,
                className: 'resize'
              }
            }
            else
            {
              event = {
                title: booking.booker ? 'Prenotazione ' + booking.bookingNumber + '\nPrenotante:\n' + booking.booker.name + '\nPaziente:\n' + booking.booker.patient :
                'Prenotazione ' + booking.bookingNumber,
                start: booking.estimatedTime.companyTimeBeforeTarget,
                end: booking.estimatedTime.companyTimeAfterTarget,
                backgroundColor: '#FF7043',
                borderColor: '#E64A19',
                eventOverlap: false,
                allDay: false,
                bookingId: booking.id,
                bookingState: booking.bookingState,
                isExternalBooking: false,
                externalBookingNotes: '',
                className: 'resize'
              }
            }
            
            events.push(event);
          });

          this.calendarOptions.events = this.calendarOptions.events.concat(events);
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  },
}
</script>

<style scoped>
.DashboardCalendar{
  position: relative;
  /* z-index: -2; */
  margin: 50px;
  margin-top: 80px;
  text-align: center;
  /* display: flex; */
  align-items: center;
  /* height: 100vh; */
  min-height: 115vh;
}

:deep(table > tbody > tr > td.fc-day.fc-day-sat.fc-day-future.fc-timegrid-col a)
{
  display: inline-block;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .7rem;
}

:deep(.resize:hover)
{
  /* height: fit-content; */
}

@media (max-width: 500px), (max-height: 900px) {
  .DashboardCalendar{
    align-items: center;
    height: auto;
    display: block;
  }
}

</style>
