<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
        <div class="text-900 font-bold text-5xl mb-3">Sei un'azienda o un'organizzazione di volontariato?</div>
        <div class="text-700 text-2xl mb-5">Scopri come AmbuBook può migliorare il tuo servizio ed aiutarti nella gestione delle tue risorse.</div>
    </div>
  </div>
  <div class="secondRowContainer">
    <div class="splide" id="carousel">
      <div class="splide__track">
        <ul class="splide__list">
          <li class="splide__slide">
            <div class="firstRowContainers efficiencyContainer">
              <div class="iconContainer">
                <i class="pi pi-clock iconImage"></i>
              </div>
              <div>
                <h1 class="titleContainer">Efficienza.</h1>
              </div>
              <div class="textContainer">
                <p>
                  Migliora la tua gestione del tempo grazie all'efficiente organizzazione di AmbuBook attraverso i calendari!
                  La piattaforma ti permetterà di tenere traccia dei servizi comodamente da un calendario aggiornato automaticamente in base alle prenotazioni.
                </p>
              </div>
            </div>
          </li>
          <li class="splide__slide">
            <div class="firstRowContainers SpeedContainer">
              <div class="iconContainer">
                <i class="pi pi-bolt iconImage"></i>
              </div>
              <div>
                <h1 class="titleContainer">Velocità.</h1>
              </div>
              <div class="textContainer">
                <p>
                 Velocizza il tuo lavoro eliminando i tempi di analisi per ogni prenotazione!
                 <br/>AmbuBook si occuperà di ricavare i dati necessari al servizio direttamente da chi prenota, in questo modo, eliminerai i tempi richiesta informazioni e potrai dedicarti ad altre attività!
                </p>
              </div>
            </div>
          </li>
          <li class="splide__slide">
            <div class="firstRowContainers visibilityContainer">
              <div class="iconContainer">
                <i class="pi pi-users iconImage"></i>
              </div>
              <div>
                <h1 class="titleContainer">Visibilità.</h1>
              </div>
              <div class="textContainer">
                <p>
                  Aumenta la tua visibilità per incerementare i servizi svolti!
                  Grazie ad una rete in costante ampliamento, AmbuBook aumenterà la tua visibilità a partire dalla comunità vicino a te!
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
        <div class="text-900 font-bold text-5xl mb-3">Controlla i tuoi veicoli.</div>
        <div class="text-700 text-2xl mb-5">Avrai la possibilità di caricare i tuoi veicoli e di definirne dati, costi e disponibilità.</div>
    </div>
  </div>
  <div class="thirdRowContainer">
    <div class="vehiclesExplanationContainer">
      <div class="iconContainer">
        <i class="pi pi-car iconImage"></i>
      </div>
      <div>
        <h1 class="titleContainer">Il tuo garage digitale!</h1>
      </div>
      <div class="textContainer">
        <p>
          AmbuBook ti permetterà di tenere sotto controllo i tuoi veicoli controllandone i dati anagrafici, i costi fissi, variabili e le disponibilità.
          <br/>Il veicolo avrà un calendario associato che ne determinerà le disponibilità, in modo da renderlo visibile sulla piattaforma solamente negli orari scelti.
        </p>
      </div>
    </div>
    <div class="vehiclesImageContainer">
      <img src="../assets/Images/VehiclesForm.png">
    </div>
  </div>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
        <div class="text-900 font-bold text-5xl mb-3">Monitora la tua attività.</div>
        <div class="text-700 text-2xl mb-5">Grazie ad AmbuBook, avrai un'analisi del tuo lavoro a portata di click.</div>
    </div>
  </div>
  <div class="fourthRowContainer">
    <div class="statisticsImageContainer">
      <img src="../assets/Images/Statistics.png">
    </div>
    <div class="statisticsExplanationContainer">
      <div class="iconContainer">
        <i class="pi pi-chart-bar iconImage"></i>
      </div>
      <div>
        <h1 class="titleContainer">Statistiche</h1>
      </div>
      <div class="textContainer">
        <p>
          AmbuBook metterà a tua disposizione fin da subito una serie di statistiche che ti aiuteranno a monitorare al meglio il tuo lavoro.
          <br/>Ti verranno mostrati grafici sulle prenotazioni ricevute, completate e rifiutate, tutto questo in archi temporali definibili da te!
        </p>
      </div>
    </div>
  </div>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
        <div class="text-900 font-bold text-5xl mb-3">Organizza il tuo tempo.</div>
        <div class="text-700 text-2xl mb-5">Con il nostro calendario, i tuoi appuntamenti saranno consultabili a colpo d'occhio!</div>
    </div>
  </div>
  <div class="fifthRowContainer">
    <div class="calendarImageContainer">
      <img src="../assets/Images/Calendar.png">
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import Splide from '@splidejs/splide';
export default {
  name: 'CompaniesView',
  components: {
  },
  setup() {
    onMounted(() => {
      new Splide('#carousel', {
        type: 'loop',
        perPage: 1,
        autoplay: false,
        padding: {
          left: '20rem',
          right: '20rem',
        },
        breakpoints: {
          500: {
            padding: {
              left: '2rem',
              right: '2rem',
            },
          },
        },
      }).mount();
    });
  }
}
</script>

<style scoped>
@import '@splidejs/splide/dist/css/splide.min.css';

.iconImage {
  font-size: 15vh; 
  color: white;
}
.thirdRowContainer{
  display: flex;
  margin-bottom: 100px;
}
.fourthRowContainer{
  display: flex;
  margin-bottom: 100px;
}
.titleContainer{
  color: white;
  text-align: center;
  font-size: -webkit-xxx-large;
}
.emailLabel {
  font-weight: bolder; 
  margin-top: 0px; 
  font-size: 2rem;
}
.iconContainer {
  text-align: center; 
  /* padding: 100px; */
  padding-bottom: 25px;
}
.textContainer {
  color: white;
  font-size: 1.5rem;
  margin-right: 100px;
  margin-left: 100px;
  font-family: revert;
}
.firstRowContainers{
  border-radius: 5rem;
  max-width: 90%;
  margin: 100px auto;
  margin-top: 0px;
  height: 45em;
  padding: 3rem;
  align-content: center;
}
.efficiencyContainer {
  /* height: 60vh; */
  background: rgb(239,131,84);
  background: linear-gradient(90deg, rgba(239,131,84,1) 0%, rgba(241,144,102,1) 100%);
}
.SpeedContainer {
  /* height: 60vh; */
  background: rgb(79,93,117);
  background: linear-gradient(90deg, rgba(79,93,117,1) 0%, rgba(94,109,134,1) 100%);
}
.visibilityContainer {
  /* height: 60vh; */
  background: rgb(170,170,170);
  background: linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(179,179,179,1) 100%);
}
.vehiclesExplanationContainer {
  /* height: 60vh; */
  background: rgb(239, 131, 84);
  background: linear-gradient(90deg, rgba(239, 131, 84, 1) 0%, rgba(241, 144, 102, 1) 100%);
  border-radius: 0 200px 200px 0;
  margin-top: 0px;
  width: 50%;
  padding: 3rem;
  align-content: center;
}
.statisticsExplanationContainer {
  /* height: 60vh; */
  background: rgb(79,93,117);
  background: linear-gradient(90deg, rgba(79,93,117,1) 0%, rgba(94,109,134,1) 100%);
  border-radius: 200px 0px 0px 200px;
  margin-top: 0px;
  width: 50%;
  padding: 3rem;
  align-content: center;
}
.vehiclesImageContainer{
  width: 50%;
  text-align: center;
  /* height: 60vh */
  align-content: center;
}
.vehiclesImageContainer img{
  height: 60vh;
}
.statisticsImageContainer{
  width: 50%;
  text-align: center;
  /* height: 60vh; */
  align-content: center;
}
.statisticsImageContainer img{
  width: 80%;
}
.calendarImageContainer{
  width: 100%;
  text-align: center;
  height: 60vh;
  align-content: center;
  margin-bottom: 100px;
}
.calendarImageContainer img{
  height: 60vh;
}
.Contacts {
  position: relative;
  z-index: -2;
  margin: 100px;
  text-align: center;
}
@keyframes sway {
  0% {
    transform: rotate(8deg);
  }
  50% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(8deg);
  }
}
.object {
  position: absolute;
  animation: sway 2.4s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
  left: 0;
  right: 0;
  height: 5%;
  z-index: 999;
  text-transform: uppercase;
}
.object-shape {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: #2187C7;
  margin: 0 auto;
  position: relative;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  font-weight: 800;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.object-shape span {
  font-size: 22px;
  color: white;
}
.object-rope {
  height: 100%;
  width: 5px;
  background-color: #2187C7;
  content: "";
  display: block;
  margin-left: 50%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
  padding: 20vh 20px 20px 20px;
}
.logo {
  max-width: 300px;
}
p {
  font-family: sans-serif;
  text-align: center;
}
.message {
  margin-top: 40px;
}
@media (max-width: 500px) {
  .thirdRowContainer{
    display: inline;
    margin-bottom: 100px;
  }
  .fourthRowContainer{
    display: inline;
    margin-bottom: 100px;
  }
  .vehiclesExplanationContainer{
    /* height: 60vh; */
    background: rgb(239, 131, 84);
    background: linear-gradient(90deg, rgba(239, 131, 84, 1) 0%, rgba(241, 144, 102, 1) 100%);
    border-radius: 30px;
    margin: 10px;
    width: auto;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    
  }
  .statisticsExplanationContainer{
    /* height: 60vh; */
    background: rgb(79,93,117);
    background: linear-gradient(90deg, rgba(79,93,117,1) 0%, rgba(94,109,134,1) 100%);
    border-radius: 30px;
    margin: 10px;
    width: auto;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }
  .vehiclesImageContainer img{
    height: auto;
    width: -webkit-fill-available;
  }
  .vehiclesImageContainer{
    width: auto;
    display: none;
  }
  .statisticsImageContainer img{
    height: auto;
    width: -webkit-fill-available;
  }
  .statisticsImageContainer{
    width: auto;
    display: none;
  }
  .emailLabel {
    font-size: unset;
  }
  .titleContainer{
    font-size: xx-large;
  }
  .textContainer {
    color: white;
    font-size: 1.3rem;
    margin-right: 50px;
    margin-left: 50px;
    font-family: revert;
  }
  .textContainer p{
    font-size: smaller;
  }
  .iconImage {
    font-size: 3rem; 
    color: white;
  }
  .iconContainer {
    padding: 25px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .secondRowContainer {
    display: inline;
  }
  .firstRowContainers{
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 120vw;
    padding: 0;
    width: -webkit-fill-available;
    border-radius: 50px;
    margin-bottom: 50px;
    margin: 0px auto;
    margin-bottom: 50px;
  }
  .efficiencyContainer {
    background: rgb(239,131,84);
    background: linear-gradient(90deg, rgba(239,131,84,1) 0%, rgba(241,144,102,1) 100%);
  }
  .SpeedContainer {
    background: rgb(79,93,117);
    background: linear-gradient(90deg, rgba(79,93,117,1) 0%, rgba(94,109,134,1) 100%);
  }
  .visibilityContainer {
    background: rgb(170,170,170);
    background: linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(179,179,179,1) 100%);
  }
  .calendarImageContainer{
    height: auto;
  }
  .calendarImageContainer img{
    max-width: 98%;
    height: auto;
  }
}
</style>