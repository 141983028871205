<template>
  <div class="RecoverPasswordContainer">
    <div class="containerHeader"> 
      <i class="pi pi-user-edit" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>Recupera Password</h1>
      <p v-if="showEmail">Inserisci la tua mail per recuperare la password.</p>
      <p v-if="showPin" style="max-width: 300px;">Inserisci il codice che ti abbiamo inviato via mail, successivamente crea la nuova password e confermala.</p>
    </div>
    <div class="containerContent">
      <span class="p-float-label p-input-icon-right" v-if="showEmail">
        <IconField iconPosition="right"> 
          <InputIcon class="pi pi-envelope"></InputIcon>
          <InputText v-model="GenericEmail" :loading="loading" />
        </IconField>
        <label :for="GenericEmail">Email</label>
      </span>
      <span class="p-float-label p-input-icon-right" v-if="showPin">
        <IconField iconPosition="right"> 
          <InputOtp v-model="GenericPin" integerOnly :length="6"/>
        </IconField>
        <label :for="GenericPin"></label>
      </span>
      <span class="p-float-label p-input-icon-right" v-if="showPin">
        <IconField iconPosition="right"> 
          <Password v-model="NewPassword" toggleMask :feedback="false" :loading="loading"/>
        </IconField>
        <label :for="NewPassword">Nuova Password</label>
      </span>
      <span class="p-float-label p-input-icon-right" v-if="showPin">
        <IconField iconPosition="right"> 
          <Password v-model="NewPasswordConfirm" toggleMask :feedback="false" :loading="loading"/>
        </IconField>
        <label :for="NewPasswordConfirm">Conferma password</label>
      </span>
    </div>
    <div class="containerFooter"> 
      <span>
        <Button label="Avanti" @click="handleRecoverPassword" rounded />
      </span>
    </div>
    <div class="containerReturnBackButton" @click="ViewLoginForm"> 
      <span> 
        <i class="pi pi-arrow-left"></i>
      </span>
      <span> 
        <p>Torna all'accesso</p>
      </span>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
</template>

<script>
import API from '@/api/API';
import { store } from '../store';

export default {
  name: 'RecoverPasswordForm',
  data(){
      return{
        //DEBUG
        debug: true,

        //Generic Variables
        GenericEmail:'',
        GenericPin:'',
        NewPassword: '',
        NewPasswordConfirm: '',
        loading: false,
        showEmail: true,
        showPin: false,
        showPassword: false,

      }
  },
  methods: {
    async handleRecoverPassword() {
      try 
      {
        //Regex
        const passwordRegex = /^(?=.*[!@#$%^&*_\-])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        this.GenericEmail = this.GenericEmail.toString().trim();
        
        if(this.debug)
        {
          console.log('Email: ' + this.GenericEmail);
          console.log('NewPassword: ' + this.NewPassword);
        }
        if(this.showEmail){
          //Verifica dei dati inseriti dall'utente
          if(this.GenericEmail == '' || !emailRegex.test(this.GenericEmail))
          {
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Mail non inserita correttamente.',life: 2500});
          }
          else
          {
            //Chiamata API per inviare la mail con il PIN
            const response = await API.UserAPI.SendResetPasswordEmail(this.GenericEmail);
            if (response.status === 200) 
            {
              this.$refs.toast.add({severity:'success', summary: 'Controlla la tua casella di posta!', detail: 'Ti abbiamo inviato un codice di sicurezza. Inseriscilo per procedere.',life: 5000});
              this.showEmail = false;
              this.showPin = true;
            }
          }
        }
        else if(this.showPin){
          //Inizio a mostrare il caricamento
          this.loading = true;

          //Eseguo il trim del codice (Non serve ma è una sicurezza in più)
          this.GenericPin = this.GenericPin.toString().trim();

          if(this.debug){
              console.log("DEBUG - PinCode: " + this.GenericPin);
              console.log("DEBUG - NewPassword: " + this.NewPassword);
              console.log("DEBUG - NewPasswordConfirm: " + this.NewPasswordConfirm);
          }

          //Se il codice non è di 6 caratteri esatti c'è un errore di inserimento
          if(this.GenericPin.length != 6){
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Pin non digitato correttamente.',life: 2500});
          }
          else{

            if(this.NewPassword != this.NewPasswordConfirm){
              this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Le due password non coincidono.',life: 2500});
            }
            else{

              if(!passwordRegex.test(this.NewPassword)){
                this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'La password deve contenere almeno una minuscola, una maiuscola, un carattere speciale e un numero.',life: 3000});
              }
              else{

                //Chiamata API per cambiare la password con PIN
                const response = await API.UserAPI.ResetPassword(this.GenericEmail,this.GenericPin,this.NewPassword);
                if (response.status == 200) 
                {
                  this.$refs.toast.add({severity:'success', summary: 'Compeltato!', detail: 'La tua password è stata cambiata con successo!',life: 2500});
                  
                  //Waiting per mostrare il toast di conferma
                  await new Promise(resolve => setTimeout(resolve, 3000));
                  this.loading = false;
                  this.$router.replace('/');
                }
              }
            }
          }
        }
      } 
      catch (error) 
      {
        this.ManageError(error);
      }
      finally
      {
        this.loading=false;
      }
    },
    ManageError(error){
      if(this.debug){
        console.log('DEBUG - Error response data: ' + error.response.data);
        console.log('DEBUG - Error response key: ' + error.response.data.key);
        console.log('DEBUG - Error response description: ' + error.response.data.description);
      }
      switch(error.response.data.key){
        case 33:
          this.$refs.toast.add({severity:'error', summary: 'Password non corretta', detail: 'La password attuale che hai inserito non è corretta.',life: 5000});
          break;
        case 43:
          this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Il codice inserito non è corretto. Preghiamo di riprovare.',life: 3000});
          this.GenericPin = "";
          break;
        case 47:
          this.$refs.toast.add({severity:'warn', summary: 'Attenzione', detail: 'Tempo intercorso dall ultimo recupero della password troppo breve. Per motivi di sicurezza preghiamo di riprovare più tardi.',life: 5000});
          break;
        default:
          this.$refs.toast.add({severity:'error', summary: 'Qualcosa non sta funzionando...', detail: 'Ci scusiamo per il disagio, contatta il supporto per segnalare.',life: 5000});
          break;
      }
      
    },

    ViewLoginForm(){
      store.commit('SwitchResetLogin');
      if(this.debug){
        console.log('DEBUG - IsRecoverPasswordFormVisible: ' + store.getters.IsRecoverPasswordVisible);
      }
    }
  },
}
</script>


<style scoped>
.RecoverPasswordContainer{
    padding: 70px;
    border-radius: 80px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    max-width: 700px;
    position: relative;
    margin: 200px auto;
    margin-top: 50px;
    position: relative;
    height: fit-content;
    text-align: center;
    background-color: white;
}
.RecoverPasswordContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerHeader{
  margin-bottom: 25px;
  text-align: -webkit-center;
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  margin: 15px;
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  width: 150px;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.containerReturnBackButton span i{
  font-size: 0.7rem; 
  color: grey; 
  margin-top: 20px;
  margin-right: 10px;
  transition: 0.5s;
}
.containerReturnBackButton span i:hover{
  cursor: pointer;
}
.containerReturnBackButton{
  position: relative;
  display: flex;
  display: inline-flex;
  color: grey;
  transition: 0.5s;
  font-size: 1rem;
  align-items: baseline;
}
.containerReturnBackButton:hover{
  cursor: pointer;
}
:deep(.p-inputotp-input){
  width: revert-layer !important;
}
:deep(.p-inputotp){
  width: 300px;
  justify-content: center;
}
@media (max-height: 900px){
    .RecoverPasswordContainer{
      zoom:90%;
      margin: 200px auto;
    }
  }

@media (max-width: 1279px) and (min-width: 501px) {
    .RecoverPasswordContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .RecoverPasswordContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 500px) {
    .RecoverPasswordContainer{
      padding: 10px;
      border-radius: 20px;
      margin: 10px;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 10px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    .containerReturnBackButton{
      margin-bottom: 10px;
    }
  }

</style>