import axios from 'axios';
import config from '@/config';

export default {
    async BookService(jwt, item, userId, search, recaptchaToken) {
        const resp = await axios.post(`${config.backendIP}/api/v1/booking`, {
            recaptcha: recaptchaToken,
            availabilityId: item.availabilityID,
            companyId: item.companyID,
            serviceId: item.serviceID,
            userId: userId,
            dateTimeToLocation: search.dateTime,
            fromLocation: search.fromLocation,
            toLocation: search.toLocation,
            waitingTime: search.waitingTime,
            transportedInfo: item.transportedInfo,
            serviceType: search.serviceType
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async GetBookingsDataTable(query, companyId, jwt) {
        const resp = await axios.post(`${config.backendIP}/api/v1/company/${companyId}/bookings`, query, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async AnswerBooking(jwt, bookingId, answer) {
        const resp = await axios.post(`${config.backendIP}/api/v1/booking/answer`, {
            bookingId: bookingId,
            answer: answer
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async CompleteBooking(jwt, bookingId, answer) {
        const resp = await axios.post(`${config.backendIP}/api/v1/booking/complete`, {
            bookingId: bookingId,
            completed: answer
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async GetBooking(jwt, bookingId) {
        const resp = await axios.get(`${config.backendIP}/api/v1/booking/${bookingId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async CancelBooking(jwt, bookingId) {
        const resp = await axios.put(`${config.backendIP}/api/v1/user/cancel-booking/${bookingId}`, {}, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async GetUserBooking(jwt, bookingId) {
        const resp = await axios.get(`${config.backendIP}/api/v1/user/booking/${bookingId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async GetBookingsCalendar(jwt, serviceId, companyId, start, end) {
        const resp = await axios.post(`${config.backendIP}/api/v1/bookings/calendar`, {
            serviceId: serviceId,
            companyId: companyId,
            startTime: start,
            endTime: end
        }, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async GetWaitingBookings(jwt, companyId) {
        const resp = await axios.get(`${config.backendIP}/api/v1/company/${companyId}/waiting-bookings`, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async PostExternalBooking(jwt, booking) {
        const resp = await axios.post(`${config.backendIP}/api/v1/external/booking`, booking, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async PutExternalBooking(jwt, booking) {
        const resp = await axios.put(`${config.backendIP}/api/v1/external/booking`, booking, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async DeleteExternalBooking(jwt, bookingId) {
        const resp = await axios.delete(`${config.backendIP}/api/v1/external/booking/${bookingId}`, {
            headers: {
                Authorization: `${jwt}`
            }
        });

        return resp;
    },
    async GetBookingPDF(jwt, bookingId, type) {
        const resp = await axios.get(`${config.backendIP}/api/v1/booking/pdf/${bookingId}/${type}`, {
            headers: {
                Authorization: `${jwt}`,
            },
            responseType: "arraybuffer"
        });

        return resp;
    }
};