<template>
  <div class="VehicleFormContainer">
    <div class="containerHeader"> 
      <i class="pi pi-car" style="font-size: 2.5rem; color: #EF8354;"></i>
      <h1>Dati veicolo</h1>
      <p>Qui puoi creare, modificare o eliminare il tuo mezzo.</p>
      <div class="ActiveServiceContainer">
        <i v-if="!isOnMobileDisplay" style="margin-right: 15px; color: darkgray;" class="pi pi-info-circle" v-tooltip="'Se il veicolo è attivo sulla piattaforma non è possibile eseguire modifiche o eliminarlo. Per modificarlo, disattivare il veicolo attraverso l\'interruttore a fianco.'"></i>
        <div v-if="IsThisServiceActive" class="ActiveServiceLed" style="background-color: green;"></div>
        <div v-if="!IsThisServiceActive" class="ActiveServiceLed" style="background-color: red;"></div>
        <p v-if="IsThisServiceActive">Veicolo attivo</p>
        <p v-if="!IsThisServiceActive">Veicolo non attivo</p>
        <InputSwitch v-model="IsThisServiceActive" @change="manageVehicleActivation" />
      </div>
    </div>
    <div class="masterContainerContent">
      <Panel header="Dati generali veicolo" class="panelContainer">
        <div class="containerContent">
            <template v-for="(field, index) in VehiclePersonalDataFormFields" :key="index">
              <div class="inputAndTooltipContainer">
                <span v-if="(field.type === 'inputnumber') || field.type != 'inputnumber'" class="p-float-label p-input-icon-right">
                  <IconField iconPosition="right">
                    <template v-if="!IsThisServiceActive">
                      <InputIcon :class="field.iconClass"></InputIcon>
                    </template>
                    <template v-if="field.type === 'inputtext'">
                      <InputText v-model="$data[field.id]" :loading="loading" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                    </template>
                    <template v-else-if="field.type === 'dropdown'">
                      <Dropdown v-model="$data[field.id]" :options="$data[field.vehicleTypeOptions]" optionLabel="name" optionValue="code" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                    </template>
                    <!-- <template v-else-if="field.type === 'inputnumber'">
                      <InputNumber v-model="$data[field.id]" mode="decimal" showButtons :min="field.min" :max="field.max" :suffix="field.suffix" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                    </template> -->
                    <template v-else-if="field.type === 'inputnumber'">
                      <InputNumber v-model="$data[field.id]" mode="decimal" showButtons :min="field.min" :max="field.max" :suffix="field.suffix" :inFractionDigits="2" :maxFractionDigits="2" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                    </template>
                  </IconField>
                  <label v-if="(field.type === 'inputnumber') || field.type != 'inputnumber'" :for="field.id">{{ field.label }}</label>
                </span>
                <i v-if="!isOnMobileDisplay" class="pi pi-info-circle" v-tooltip="field.tooltip"></i>
              </div>
            </template>
            <div class="inputAndTooltipContainer">
              <FloatLabel>
                <vue-google-autocomplete
                          inputId="vehicleAddress"
                          ref="vehicleRawAddress"
                          id="googleVehicleAddress"
                          classname="form-control"
                          placeholder=""
                          v-on:placechanged="getVehicleAddressData"
                          country="it"
                          class="googleAutocomplete"
                          :disabled="IsThisServiceActive"
                          v-bind:class="{ 'p-filled': isAddressInputFilled }"
                          @input="checkInputFilled"
                        />
                <label for="vehicleAddress">*Indirizzo posizione veicolo</label>
              </FloatLabel>
              <i v-if="!isOnMobileDisplay" class="pi pi-info-circle" v-tooltip="'Indirizzo in cui è collocato il veicolo. Verrà utilizzato per eseguire i calcoli di distanza durante la ricerca dell\'utente.'"></i>
            </div>
            <FloatLabel>
              <Textarea :disabled="IsThisServiceActive" v-model="description" rows="5" cols="30" inputId="notes" autoResize/>
              <label for="notes">Descrizione</label>
            </FloatLabel>
          <p style="color: darkgrey; font-size: x-small;">I dati contrassegnati dall'asterisco (*) sono obbligatori.</p>
        </div>
      </Panel>
      <div class="costContainer">
        <Panel header="Costi fissi" class="panelContainer">
          <div class="containerContent">
              <template v-for="(field, index) in VehicleStaticCostDataFormFields">
                <div class="inputAndTooltipContainer">
                  <span v-if="(field.type === 'inputnumber') || field.type != 'inputnumber'" :key="index" class="p-float-label p-input-icon-right">
                    <IconField iconPosition="right">
                      <template v-if="!IsThisServiceActive">
                        <InputIcon :class="field.iconClass"></InputIcon>
                      </template>
                      <template v-if="field.type === 'inputtext'">
                        <InputText v-model="$data[field.id]" :loading="loading" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                      </template>
                      <template v-else-if="field.type === 'dropdown'">
                        <Dropdown v-model="$data[field.id]" :options="$data[field.vehicleTypeOptions]" optionLabel="name" optionValue="code" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                      </template>
                      <template v-else-if="field.type === 'inputnumber'">
                        <InputNumber v-model="$data[field.id]" mode="decimal" showButtons :min="field.min" :max="field.max" :suffix="field.suffix" :step="field.step" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                      </template>
                      <template v-else-if="field.type === 'inputnumberfloat'">
                        <InputNumber v-model="$data[field.id]" mode="decimal" showButtons :min="field.min" :max="field.max" :suffix="field.suffix" :inFractionDigits="2" :maxFractionDigits="2" :disabled="IsThisServiceActive" @change="formIsDirtyChange"/>
                      </template>
                    </IconField>
                    <label v-if="(field.type === 'inputnumber') || field.type != 'inputnumber'" :for="field.id">{{ field.label }}</label>
                  </span>
                  <i v-if="!isOnMobileDisplay" class="pi pi-info-circle" v-tooltip="field.tooltip"></i>
                </div>
              </template>
            <p style="color: darkgrey; font-size: x-small;">I dati contrassegnati dall'asterisco (*) sono obbligatori.</p>
          </div>
        </Panel>
        <Panel header="Costi di operatività a zone" class="panelContainer">
          <ScrollPanel
          style="width: 100%; height: 200px"
          :pt="{
              wrapper: {
                  style: 'border-right: 10px solid var(--surface-50); border-bottom: 10px solid var(--surface-50);'
              },
              bary: 'surface-300 opacity-100 border-noround'
          }">
              <div class="containerContent">
                <Accordion :multiple="true" style="width: 310px">
                  <template v-for="(zone, zoneIndex) in zones" :key="zoneIndex">
                    <AccordionTab :header="zone.name">
                      <template v-for="(field, fieldIndex) in zone.fields" :key="fieldIndex">
                        <span v-if="(field.type === 'inputnumber') || field.type != 'inputnumber'" class="p-float-label p-input-icon-right">
                          <IconField iconPosition="right">
                            <InputIcon :class="field.iconClass"></InputIcon>
                            <template v-if="field.type === 'inputnumber'">
                              <InputNumber :disabled="IsThisServiceActive" class="reducedInputNumber" v-model="zone[field.id]" mode="decimal" showButtons :min="field.min" :max="field.max" :suffix="field.suffix" @change="formIsDirtyChange"/>
                            </template>
                          </IconField>
                          <label v-if="(field.type === 'inputnumber') || field.type != 'inputnumber'" :for="field.id">{{ field.label }}</label>
                        </span>
                      </template>
                      <span class="deleteZoneButton" >
                        <i class="pi pi-trash" :id="zone.name" @click="deleteZone(zone.name)"></i>
                      </span>
                    </AccordionTab>
                  </template>
                </Accordion>
                <span>
                  <Button icon="pi pi-plus" rounded style="
                  min-width: 0px;
                  height: 30px;
                  width: 30px;
                  margin-bottom: 15px;
                  margin-top: 15px;"
                  severity="secondary"
                  @click="addZone"
                  :disabled="IsThisServiceActive" />
                </span>
                <span>
                  <Button style="width: 165px; height: 20px; font-size: small;" label="Simulatore viaggio" :loading="loading" rounded @click="simulatePrice" />
                </span>
                <p style="color: darkgrey; font-size: x-small;">I dati contrassegnati dall'asterisco (*) sono obbligatori.</p>
              </div>
          </ScrollPanel>
        </Panel>
      </div>
    </div>
    <div class="containerFooter"> 
      <span>
        <Button :label="this.selectedVehicleID ? 'Applica modifiche' : 'Crea'" :loading="loading" rounded @click="createJsonToSend" :disabled="IsThisServiceActive" style="margin: 5px;"/>
      </span>
      <span v-if="this.selectedVehicleID">
        <Button label="Cancella veicolo" :loading="loading" rounded @click="onConfirmDelete" class="deleteButton" style="margin: 5px;"/>
      </span>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
  </div>
  <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>

  <Dialog v-model:visible="simulatePriceVisible" modal header="Simula viaggio" style="width: 25rem; z-index: 150;">
    <SimulatePrice :service-id="selectedVehicleID"></SimulatePrice>
  </Dialog>
</template>

<script>
import API from '@/api/API';
import Cookies from 'js-cookie';
import SimulatePrice from './SimulatePrice.vue';

export default {
  name: 'VehicleForm',
  components: {
    SimulatePrice
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null
    }
  },
  watch: {
    currentVehicle(newValue) {
      this.formIsDirty = false;

      console.log('Nuovo veicolo:', JSON.stringify(newValue));
        if (newValue) {
          this.selectedVehicleID=newValue.id;

          this.IsThisServiceActive = newValue.active;
          this.name = newValue.name;
          this.vehicleType = newValue.vehicleType;
          this.section = newValue.section;
          this.priority = newValue.priority;
          this.maxDistance = newValue.maxDistance;
          this.vehicleAddressIsToUpdate = true;
          this.$refs.vehicleRawAddress.update(newValue.location.address);
          this.checkInputFilled({ target: { value: newValue.location.address } });
          this.description = newValue.description;
          this.hourPrice = newValue.hourPrice;
          this.waitingTimeIncluded = newValue.waitingTimeIncluded;
          this.callPrice = newValue.callPrice;
          this.kmPrice = newValue.cost.find(costItem => costItem.useKmPrice)?.kmPrice || 0;
          this.IsThisServiceActive = newValue.isActive;
          this.populateZones(newValue.cost);
      } else {
          this.selectedVehicleID="";

          this.IsThisServiceActive = false;
          this.name = "";
          this.vehicleType = undefined;
          this.section = "";
          this.priority = undefined;
          this.maxDistance = undefined;
          this.description = "";
          this.hourPrice = undefined;
          this.waitingTimeIncluded = undefined;
          this.callPrice = undefined;
          this.kmPrice = undefined;
          this.$refs.vehicleRawAddress.update("");
          this.checkInputFilled({ target: { value: "" } });
          this.zones = [];
      }
    }
  },
  data(){
      return{
        //DEBUG
        debug: false,

        //Generic Variables
        loading: false,
        isAddressInputFilled: false,

        //Vehicle Data
        selectedVehicleID:"",
        vehicleRawAddress: null,
        formattedVehicleAddress:"",
        IsThisServiceActive: null,
        name: "",
        vehicleType: undefined,
        location: {
          address: "",
          lat: undefined,
          lon: undefined
        },
        section: "",
        priority: undefined,
        maxDistance: undefined,
        description: "",
        companyId: "",

        //Fixed Cost
        callPrice:undefined,
        hourPrice: undefined,
        waitingPrice:undefined,
        waitingTimeIncluded:undefined,
        kmPrice: undefined,

        //Input Dynamic Creation
        VehiclePersonalDataFormFields: [
          { id: 'name', label: '*Nome veicolo', type: 'inputtext', iconClass:'', tooltip:'Rappresenta il nome univoco del veicolo. (Ad esempio la targa oppure il numero identificativo del veicolo.)' },
          { id: 'vehicleType', label: '*Tipo veicolo', type: 'dropdown', iconClass:'pi pi-car', vehicleTypeOptions:'VehicleTypeOptions', tooltip:'Indica la tipologia di veicolo che si andrà ad inserire.' },
          //Spunta che mette lo stesso indirizzo sede
          { id: 'section', label: 'Sezione veicolo', type: 'inputtext', iconClass:'', tooltip:'Rappresenta la sezione o sede in cui è collocato il veicolo nel caso l\'organizzazione abbia più sedi.' },
          { id: 'priority', label: '*Priorità veicolo', type: 'inputnumber', suffix: '', min: 1, max:25, tooltip:'Rappresenta la priorità con cui si vuole apparire durante la ricerca con questo veicolo (per tipo di veicolo e stesso indirizzo). Più il numero è basso e più il veicolo verrà mostrato in cima rispetto agli altri veicoli dell\'organizzazione.'},
          { id: 'maxDistance', label: '*Distanza max. percorribile', type: 'inputnumber', suffix: ' Km', min: 0, max:9999, tooltip:'Indica la distanza massima entro la quale il veicolo può essere mostrato disponibile. ATTENZIONE: La distanza massima è comprensiva di ritorno.'},
        ],
        VehicleStaticCostDataFormFields: [
          { id: 'callPrice', label: '*Costo chiamata', type: 'inputnumber', suffix: ' €', min: 0, max:9999, tooltip:'Ad ogni prenotazione verrà aggiunto questo costo considerato fisso.'},
          { id: 'kmPrice', label: '*Costo chilometrico', type: 'inputnumberfloat', suffix: ' €', min: 0, max:9999, tooltip:'Costo chilometrico da utilizzare nel caso il servizio dovesse sforare le zone a costo fisso (le zone a costo fisso non sono obbligatorie.)'},
          { id: 'hourPrice', label: '*Costo attesa orario', type: 'inputnumber', suffix: ' €', min: 0, max:9999, tooltip:'Costo da applicare per l\'attesa selezionata dall\'utente. Questo costo verrà applicato solamente se l\'attesa dovesse sforare l\'attesa inclusa nel servizio (se configurata).'},
          { id: 'waitingTimeIncluded', label: '*Minuti attesa inclusi', type: 'inputnumber', suffix: ' min', min: 0, max:30000, step:30, tooltip:'Minuti di attesa inclusi nel servizio base. Oltre a questo minutaggio, verrà utilizzato il costo orario dell\'attesa configurato. Se non è inclusa attesa nel servizio base, impostare questo dato a 0.'},
        ],

        zones: [],

        //Vehicle type selector component Constructor
        VehicleTypeOptions: [
          { name: 'Ambulanza', code: 1 },
          { name: 'Auto', code: 2 },
          { name: 'Mezzo disabili', code: 3 },
        ],

        //Simulate Price Dialog
        simulatePriceVisible: false,

        // Form is dirty
        formIsDirty: false,

        isOnMobileDisplay: window.innerWidth < 1280,
      }
  },
  methods: {
    formIsDirtyChange() {
      this.formIsDirty = true;

      this.$emit('formIsDirty', this.formIsDirty);
    },

    onConfirmDelete() {
      this.$confirm.require({
        message: `Sei sicuro di voler eliminare il veicolo ${this.name}?`,
        header: 'Conferma eliminazione veicolo',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        accept: () => {
          this.manageVehicleDelete();
        },
        reject: () => {
          return;
        }
      });
    },

    checkInputFilled(event) {
      this.isAddressInputFilled = event.target.value.trim() !== '';
    },

    //Metodo che permette di aggiungere una nuova zona con ID incrementale
    addZone() {
      let maxZoneNumber = 0;
      this.zones.forEach(zone => {
        const zoneNumber = parseInt(zone.name.split(' ')[1]);
        if (!isNaN(zoneNumber) && zoneNumber > maxZoneNumber) {
          maxZoneNumber = zoneNumber;
        }
      });
      const nextZoneNumber = maxZoneNumber + 1;
      const newZone = {
        name: `Zona ${nextZoneNumber}`,
        fields: [
          { id: `maximumKm`, label: '*Raggio zona', type: 'inputnumber', suffix: ' Km', min: 0, max:9999},
          { id: `Price`, label: '*Costo zona', type: 'inputnumber', suffix: ' €', min: 0, max:9999},
        ]
      };
      this.zones.push(newZone);
    },

    //Metodo che permette di cancellare una zona secondo il suo ID
    deleteZone(zoneName) {
      if(!this.IsThisServiceActive){
        const index = this.zones.findIndex(zone => zone.name === zoneName);
        if (index !== -1) {
          this.zones.splice(index, 1);
        }
      }
    },

    buildCostVector(zones, kmPrice) {
      const costVector = [];

      // Aggiungi un nodo per ogni zona
      zones.forEach((zone, index) => {
        costVector.push({
          kmPrice: 0,
          maximumKm: zone.maximumKm,
          price: zone.Price,
          useKmPrice: false
        });
      });
      
      // Aggiungi l'ultimo nodo
      costVector.push({
        kmPrice: kmPrice,
        maximumKm: 0,
        price: 0,
        useKmPrice: true
      });
      
      return costVector;
    },

    buildVehicleLocation(){
      if (this.selectedVehicleID && this.currentVehicle && !this.vehicleRawAddress) {
        return {
          "address": this.currentVehicle.location.address,
          "lat": this.currentVehicle.location.lat,
          "lon": this.currentVehicle.location.lon
        };
      } else {
        return {
          "address": this.formattedVehicleAddress,
          "lat": this.vehicleRawAddress.latitude,
          "lon": this.vehicleRawAddress.longitude
        };
      }
    },

    buildVehicleDataJson() {
      return {
        "vehicleType": this.vehicleType,
        "callPrice": this.callPrice,
        "companyId": Cookies.get('companyId'),
        "cost": this.buildCostVector(this.zones, this.kmPrice),
        "description": this.description,
        "hourPrice": this.hourPrice,
        "location": this.buildVehicleLocation(),
        "maxDistance": this.maxDistance,
        "name": this.name,
        "priority": this.priority,
        "section": this.section,
        "waitingTimeIncluded": this.waitingTimeIncluded
      };
    },

    checkAllInputData() {
      let errorMessage = '';

      // Verifica il nome del veicolo
      if (!this.name) {
        errorMessage = 'Il nome del veicolo è obbligatorio.';
        return errorMessage;
      }

      // Verifica il tipo di veicolo
      if (!this.vehicleType) {
        errorMessage = 'Il tipo di veicolo è obbligatorio.';
        return errorMessage;
      }

      // Verifica la priorità del veicolo
      if (!this.priority) {
        errorMessage = 'La priorità del veicolo è obbligatoria.';
        return errorMessage;
      }

      // Verifica la distanza massima percorribile
      if (!this.maxDistance) {
        errorMessage = 'La distanza massima percorribile è obbligatoria.';
        return errorMessage;
      }

      // Verifica l'indirizzo base del veicolo
      if (!this.formattedVehicleAddress && !this.currentVehicle) {
        errorMessage = 'L\'indirizzo della posizione del veicolo è obbligatorio.';
        return errorMessage;
      }

      // Verifica il costo della chiamata
      if (!this.callPrice && this.callPrice != 0) {
        errorMessage = 'Il costo della chiamata è obbligatorio.';
        return errorMessage;
      }

      // Verifica il costo chilometrico
      if (!this.kmPrice && this.kmPrice != 0) {
        errorMessage = 'Il costo chilometrico è obbligatorio.';
        return errorMessage;
      }

      // Verifica il costo per ora di attesa
      if (!this.hourPrice) {
        errorMessage = 'Il costo per ora di attesa è obbligatorio.';
        return errorMessage;
      }

      // Verifica il tempo di attesa incluso
      if (!this.waitingTimeIncluded && this.waitingTimeIncluded!=0) {
        errorMessage = 'Il tempo di attesa incluso è obbligatorio.';
        return errorMessage;
      }

      return errorMessage;
    },

    checkCostData(jsonData) {
      const { cost } = jsonData;
      let errorMessage = '';
      const maxKmSet = new Set();
      cost.forEach((zone, index) => {
        if (maxKmSet.has(zone.maximumKm)) {
          errorMessage = `Il valore massimo di chilometri per la zona ${index + 1} è duplicato.`;
          return errorMessage;
        }
        //console.log(JSON.stringify(zone));
        maxKmSet.add(zone.maximumKm);

        if (!zone.useKmPrice && (zone.price === undefined || zone.price === '')) {
          errorMessage = `Il prezzo per la zona ${index + 1} è obbligatorio quando la tariffa a chilometro non è attiva.`;
          return errorMessage;
        }
      });

      return errorMessage;
    },

    createJsonToSend(){
      var errorMessage = this.checkAllInputData();
      if(errorMessage === ""){
        var finalJson = this.buildVehicleDataJson();
        errorMessage = this.checkCostData(finalJson);
        if(errorMessage===""){
          const jwt = Cookies.get('token');
          if (jwt === undefined) 
          {
            if(this.selectedVehicleID){
              this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Non sei loggato, effettua il login per aggiornare i dati del veicolo.',life: 2500});
            }
            else{
              this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Non sei loggato, effettua il login per inserire un nuovo veicolo.',life: 2500});
            }
          }
          else{
            if(!this.selectedVehicleID){
              this.createNewVehicle(jwt, finalJson);
            }
            else{
              this.updateVehicleData(jwt, finalJson);
            }
          }
        }
        else{
          this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: errorMessage,life: 3000});
        }
      }
      else{
        this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: errorMessage,life: 3000});
      }
      //console.log(JSON.stringify(finalJson));
    },

    async createNewVehicle(jwt, jsonData){
      const response = await API.ServiceAPI.PostService(jwt,jsonData).then(response => {
        //Mando conferma all'utente del nuovo veicolo
        this.$refs.toast.add({severity:'success', summary: 'Completato', detail: 'Il tuo veicolo è stato aggiunto correttamente!',life: 3500});
        
        //Waiting per mostrare il toast di conferma
        setTimeout(() => {}, 4000);

        //Ricarico la pagina per mostrare il nuovo veicolo in lista
        window.location.reload();
      }).catch(error => {
        if (error.response.status === 409) {
          if (error.response.data.key === 89) {
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Tipo di veicolo già presente allo stesso indirizzo con stessa priorità.',life: 3000});
          } else if (error.response.data.key === 86) {
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Veicolo già presente con stesso nome.',life: 3000});
          } else {
            this.$refs.toast.add({severity:'error', summary: 'Qualcosa è andato storto', detail: 'Errore',life: 3000});
          }
        } else {
          this.$refs.toast.add({severity:'error', summary: 'Qualcosa è andato storto', detail: 'Errore',life: 3000});
        }
      });
    },

    async updateVehicleData(jwt, jsonData){
      const response = await API.ServiceAPI.UpdateService(jwt, this.selectedVehicleID ,jsonData).then(response => {
        //Mando conferma all'utente del nuovo veicolo
        this.$refs.toast.add({severity:'success', summary: 'Completato', detail: 'Il tuo veicolo è stato aggiornato correttamente!',life: 3500});
        
        //Waiting per mostrare il toast di conferma
        setTimeout(() => {}, 4000);

        //Ricarico la pagina per mostrare il nuovo veicolo in lista
        window.location.reload();
      }).catch(error => {
        if (error.response.status === 409) {
          if (error.response.data.key === 89) {
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Tipo di veicolo già presente allo stesso indirizzo con stessa priorità.',life: 3000});
          } else if (error.response.data.key === 86) {
            this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Veicolo già presente con stesso nome.',life: 3000});
          } else {
            this.$refs.toast.add({severity:'error', summary: 'Qualcosa è andato storto', detail: 'Errore',life: 3000});
          }
        } else {
          this.$refs.toast.add({severity:'error', summary: 'Qualcosa è andato storto', detail: 'Errore',life: 3000});
        }
      });
    },

    manageVehicleActivation(){
      if(!this.selectedVehicleID){
        this.IsThisServiceActive = !this.IsThisServiceActive;
        return
      }
      const jwt = Cookies.get('token');
      if (jwt === undefined) 
      {
        this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Non sei loggato, effettua il login per abilitare o disabilitare questo veicolo.',life: 2500});
      }
      else{
        this.ActiveOrDisableVehicle(jwt);
      }
    },

    async ActiveOrDisableVehicle(jwt){
      const response = await API.ServiceAPI.ActiveOrDisableVehicle(jwt,this.IsThisServiceActive, this.selectedVehicleID);
      if(response.status == 200){
      }
      else if (response.status == 429){
        this.IsThisServiceActive = !this.IsThisServiceActive;
        this.$refs.toast.add({severity:'warn', summary: 'Attenzione', detail: 'Per motivi di sicurezza, è necessario attendere 15 secondi prima di cambiare lo stato del veicolo.',life: 3000});
      }
      else{
        this.$refs.toast.add({severity:'error', summary: 'Qualcosa è andato storto', detail: 'Errore',life: 3000});
      }
    },

    manageVehicleDelete(){
      const jwt = Cookies.get('token');
      if (jwt === undefined) 
      {
        this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: 'Non sei loggato, effettua il login per aggiornare i dati del veicolo.',life: 2500});
      }
      else{
        if(this.selectedVehicleID){
          this.DeleteVehicle(jwt);
        }
      }
    },

    async DeleteVehicle(jwt){
      const response = await API.ServiceAPI.DeleteService(jwt, this.selectedVehicleID);
      if(response.status == 200){

        this.$refs.toast.add({severity:'success', summary: 'Completato', detail: 'Il tuo veicolo è stato rimosso correttamente!',life: 3500});
        
        //Waiting per mostrare il toast di conferma
        await new Promise(resolve => setTimeout(resolve, 4000));

        //Ricarico la pagina per mostrare il nuovo veicolo in lista
        window.location.reload();
      }
      else{
        this.$refs.toast.add({severity:'error', summary: 'Qualcosa è andato storto', detail: 'Errore',life: 3000});
      }
    },

    getVehicleAddressData: function (addressData, placeResultData, id) {
      this.vehicleRawAddress = addressData;
      this.formattedVehicleAddress = placeResultData.formatted_address;
    },
    simulatePrice: function(){
      if (!this.selectedVehicleID) {
        this.$refs.toast.add({severity:'error', summary: 'Attenzione', detail: "Salva il veicolo prima di simulare il prezzo!",life: 3000});
        return;
      }
      this.simulatePriceVisible = true;
    },
    populateZones(cost) {
      this.zones = cost.filter(zone => !zone.useKmPrice).map((zone, index) => {
        return {
          name: `Zona ${index + 1}`,
          maximumKm: zone.maximumKm,  // Aggiungere questa linea
          Price: zone.price,          // Aggiungere questa linea
          fields: [
            { id: `maximumKm`, label: '*Raggio zona', type: 'inputnumber', suffix: ' Km', min: 0, max: 9999 },
            { id: `Price`, label: '*Costo zona', type: 'inputnumber', suffix: ' €', min: 0, max: 9999 },
          ]
        };
      });
    }
  }
}
</script>

<style scoped>
.inputAndTooltipContainer{
  display: flex;
  align-items: center;
}
.inputAndTooltipContainer i{
  color: darkgrey;
}
.googleAutocomplete{
  font-family: var(--font-family);
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
  width: 300px;
}

.googleAutocomplete:disabled {
  opacity: 0.6;
}

.ActiveServiceContainer{
  display: flex;
  color: grey;
  align-items: center;
  justify-content: center;
}
.ActiveServiceContainer p{
  margin-right: 10px;
  margin-left: 10px;
}
.ActiveServiceLed{
  height: 10px;
  width: 10px;
  border-radius: 10px;
}
.deleteZoneButton{
  color: grey;
}
.deleteZoneButton:hover{
  cursor: pointer;
  background-color: #74747425;
  border-radius: 5px;
}
:deep(.p-accordion .p-accordion-content){
  display: grid;
  padding: 1rem;
}
:deep(.p-accordion .p-accordion-header .p-accordion-header-link ){
  padding: 0.5rem;
}
.masterContainerContent{
  display: flex;
}
.costContainer{
  display: block;
}
.panelContainer{
  margin: 15px;
}
.VehicleFormContainer{
  padding: 70px;
  border-radius: 0 80px 80px 0;
  box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
  position: relative;
  height: fit-content;
  text-align: center;
  background-color: white;
}
.VehicleFormContainer .p-float-label{
    margin: 15px;
    width: max-content;
}
.containerHeader{
  /* margin-bottom: 25px; */
}
.containerContent{
  position: relative;
  align-items: center;
  display: inline-grid;
  /* height: -webkit-fill-available; */
}
.containerFooter{
  margin-top: 25px;
}
.checkBoxContainer{
  margin-top: 20px;
  font-size: medium;
  color: gray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
}
.checkBoxContainer p{
  margin: 10px;
}
.p-button{
  background-color: #EF8354;
  border-color: #EF8354;
  min-width: 150px;
  /* margin-right: 10px; */
}
.deleteButton{
  background-color: #ef4444;
  border-color: #ef4444;
}
:deep(.p-checkbox-box:hover){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox .p-checkbox-box.p-highlight){
  background:#EF8354;
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-checkbox-box:focus){
  border-color: #EF8354;
  box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.p-button:hover{
  border-color: #bc6844;
  background-color: #bc6844;
}
.deleteButton:hover{
  border-color: #dc2626;
  background-color: #dc2626;
}

:deep(.p-inputtext){
  width: 300px;
}
:deep(.reducedInputNumber .p-inputtext) {
  width: 200px;
}
:deep(.p-inputtext:hover){
    border-color: #EF8354;
}
:deep(.p-inputtext:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
:deep(.p-button:focus){
    border-color: #EF8354;
    box-shadow: 0 0 0 0.2rem rgba(239, 131, 84, 0.25);
}
.containerReturnBackButton span i{
  font-size: 0.7rem; 
  color: grey; 
  margin-top: 20px;
  margin-right: 10px;
  transition: 0.5s;
}
.containerReturnBackButton span i:hover{
  cursor: pointer;
}
.containerReturnBackButton{
  position: relative;
  display: flex;
  display: inline-flex;
  color: grey;
  transition: 0.5s;
  font-size: 1rem;
  align-items: baseline;
}
.containerReturnBackButton:hover{
  cursor: pointer;
}
.align-items-center{
  color: grey;
  max-width: 300px;
  margin: 15px;
}
:deep(.p-checkbox){
  margin-right: 10px !important;
}
:deep(.p-dropdown){
  width: 300px;
  text-align: left;
}
:deep(.p-dropdown .p-dropdown-label:focus){
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0);
}
:deep(.p-inputnumber){
  max-width: 300px;
}
.advancedSearchBottomToggle{
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: #747474;
}
.advancedSearchBottomToggle i, .advancedSearchBottomToggle p{
  margin: 10px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.advancedSearchBottomToggle i{
  font-size: small;
}

@media (max-width: 1280px) and (min-width: 501px) {
    .VehicleFormContainer{
      zoom:100%;
    }
    .containerContent{
      margin: 0px;
    }
    .VehicleFormContainer .p-float-label{
      margin: 20px;
      width: max-content;
    }
  }
  @media (max-width: 1279px) {
    .masterContainerContent{
      display: inline;
    }
    :deep(.p-panel .p-panel-content){
      padding: 0px;
      padding-top: 20px;
    }
    .VehicleFormContainer{
      padding: 0px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 20px;
      margin: 0px;
    }
    .containerHeader{
      margin: 10px;
      margin-top: 30px;
    }
    .containerContent{
      margin: 0px;
    }
    .containerFooter{
      margin: 30px;
    }
    :deep(.p-inputtext){
      width: 300px;
      height: 60px;
    }
    .containerReturnBackButton{
      margin-bottom: 10px;
    }
    :deep(.p-dropdown){
      width: 300px;
      height: 60px;
      line-height: 36px;
    }
  }

</style>