<template>
  <div class="DashboardBookings">
    <h1>Le tue prenotazioni</h1>
    <BookingsDataTable></BookingsDataTable>
  </div>
 
</template>

<script>
import BookingsDataTable from '@/components/BookingsDataTable.vue';

export default {
  name: 'DashboardBookingsView',
  data(){
    return{

    }
  },
  components: {
    BookingsDataTable
  },
  mounted() {

  },
  methods: {
    
  }
}
</script>

<style scoped>
.DashboardBookings{
  position: relative;
  z-index: -2;
  margin: 50px;
  margin-top: 100px;
  text-align: center;
  align-items: center;
  min-height: 70vh;
  min-height: 115vh;
}
@media (max-height: 900px) {
  .DashboardBookings{
    align-items: center;
    height: auto;
    display: block;
    /* margin: 10px; */
    min-height: auto;
  }
}

</style>
