<template>
<!-- Sezione dedicata al dialog di Login -->

    <Dialog
      v-model:visible="loginDialogvisible"
      modal
      :pt="{
        root: 'border-none',
        mask: {
          style: 'backdrop-filter: blur(2px); overflow: auto'
        }
      }"
      style="box-shadow: none; text-align: center; display: inline;"
    >
    <template #container="{ closeCallback }">
    <Button icon="pi pi-times" small rounded aria-label="Filter" @click="closeCallback" style="margin-bottom: 5px;"/>
    <LogInForm :-is-in-pop-up-mode="true" style="margin: 15px;"></LogInForm>
    </template>
    </Dialog>

<!-- Fine sezione dedicata al dialog di Login -->

    <div class="resultContainer" v-bind:class="[!rawItem.available ? 'disabledResult' : '']">
      <div class="resultHeader">
      </div>
      <div class="resultContent">
        <div class="resultImage">
          <div ref="imageContainer" class="image"></div>
        </div>
        <div class="resultImageAndInfosContainer">
          <div class="resultTitle">
            <div style="display: flex; align-items: center;">
              <h2>{{companyName}} {{ this.rawItem.serviceSection ? ' - Sezione ' + this.rawItem.serviceSection : '' }}</h2>
              <div class="containerRating">
              <vue3starRatings 
                v-model="ratingScore"
                :starSize="12"
                starColor="#EF8354"
                inactiveColor="#bfc0c0"
                :numberOfStars="1"
                :disableClick="true"
              />
              <p>({{ this.ratingScore }})</p>
            </div>
          </div>
            

            <div class="addressContainer" v-if="rawItem.available">
              <h6>{{formatAddress(startAddress)}}</h6>
              <i v-if="search.serviceType == 2" class="pi pi-arrow-right-arrow-left"></i>
              <i v-if="search.serviceType != 2" class="pi pi-arrow-right"></i>
              <h6>{{formatAddress(endAddress)}}</h6>
            </div>

          </div>
          <div class="resultInfos">
            <h4 v-if="rawItem.available">{{duration}} - {{distance}} km</h4>
            <i v-if="rawItem.available" class="pi pi-info-circle" v-tooltip="'La durata e distanza sono comprensivi dei viaggi di andata e ritorno presso la base del mezzo.'"></i>
            <h4 v-if="!rawItem.available">Non disponibile al momento</h4>
          </div>
        </div>
        <div class="resultPrice">
          <div class="resultPriceNumber">
            <p>Include tasse e costi</p>
            <h2>€{{price}}</h2>
          </div>
          <Button :disabled="!rawItem.available" label="Dettagli" rounded @click="OpenResultInfos()" />
        </div>
      </div>
      <div class="resultFooter">

      </div>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
</template>

<script>
import API from '@/api/API';
import Helper from '@/helper/Helpers';
import Cookies from 'js-cookie';
import { stringifyQuery } from 'vue-router';
import LogInForm from '../components/LogInForm.vue';
import { store } from '@/store';
import vue3starRatings from "vue3-star-ratings";

export default {
  name: 'Result',
  components: {
      LogInForm,
      vue3starRatings,
  },
  data(){
      return {
        companyImage: '',

        ratingScore:1,

        //Dialog Login
        loginDialogvisible: false,
      }
  },
  mounted() {
    this.setCompanyImage();
    this.IsRecoverPasswordVisible();
    this.ratingScore = this.rawItem.rating.toFixed(1);
  },
  props: {
    rawItem: Object,
    search: Object,
    companyName: String,
    startAddress: Object,
    endAddress: Object,
    duration: String,
    distance: String,
    requestedDate: String,
    serviceType: Number,
    waitingTime: Number,
    price: Number,
    image: String,
  },
  watch: {

  } ,

  methods: {
    IsRecoverPasswordVisible(){
      return store.getters.IsRecoverPasswordVisible;
    },
    setCompanyImage(){
      let _image = new Image();
      _image.src = this.image;
      _image.onload = () => {
        this.$refs.imageContainer.style.backgroundImage = `url(${_image.src})`;
      };
    },
    formatAddress(address) {
      let t = Helper.GeoHelper.removeCAPandCountry(address.address);
      return Helper.GeoHelper.trimLongAddress(t);
    },

    OpenResultInfos(){
      const jwt = Cookies.get('token');
      const userId = Cookies.get('id');
      if (jwt === undefined)
      {
        this.loginDialogvisible = true;
      } else {
        store.commit('SetResultInfo', {rawItem: this.rawItem, search: this.search})
        this.$router.push({
          name: 'resultinfo'
        });
      }
    },
  }
}
</script>


<style scoped>
.disabledResult{
  filter: brightness(93%);
}

.containerRating{
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.containerRating p{
  margin-left: 5px;
  font-size: small;
  color: gray;
}
.resultContainer{
  padding: 15px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.05);
  margin: 5px;
  min-width: 850px;
  background-color: white;
}
.resultContent{
  /*margin: 5px;*/
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-between;
}
.resultTitle{
  color: #4F5D75;
  text-align: left;
}
.resultImage{
  height: 6rem;
  width: 6rem;
  background-color: rgb(245 245 245);
  border-radius: 15px;
  padding: 10px;
}
.image{
  height: -webkit-fill-available;
  background-image: url(../assets/Images/AmbulanceNoBKG.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.resultImageAndInfosContainer{
  margin: 15px;
  /* max-height: 10vh; */
  line-height: 5px;
  flex-grow: 1;
}
.resultInfos{
  text-align: left;
  color: #EF8354;
  display: flex;
  position: relative;
  align-items: baseline;
}
.resultInfos i{
  font-size: small;
  color: #bfc0c0;
  margin: 5px;
  cursor: pointer;
}
.resultPrice{
  text-align: right;
  margin-right: 10px;
  line-height: 10px;
}
.resultPrice p{
  color: #bfc0c0;
  font-size: x-small;
  font-weight: lighter;
  margin-right: 10px;
}
.resultPriceNumber{
  display: flex;
  display: none; /** Messo temporaneamente, se dovessimo mostrare il prezzo basta togliere questo */
  position: relative;
  align-items: center;
}
.addressContainer{
  display: flex;
  position: relative;
  align-items: center;
  height: 5px;
}
.addressContainer i{
  font-size: x-small;
  margin-right: 5px;
  margin-left: 5px;
}

@media (max-width: 700px){

  .resultContainer{
    min-width: 0px;
  }
  .resultContent{
    display: unset;
    height: auto;
  }
  .resultTitle{
    line-height: 1;
  }
  .resultPrice{
    text-align: right;
    line-height: 0px;
    margin: 0px;
  }
  .resultPriceNumber{
    justify-content: end;
    font-size: small;
    margin-bottom: 5px;
    display: none; /** Messo temporaneamente, se dovessimo mostrare il prezzo basta togliere questo */
  }
  .resultImage{
    width: -webkit-fill-available;
  }
  .resultInfos{
    line-height: 1;
  }
  .resultImageAndInfosContainer{
    margin: 15px;
    max-height: auto;
    line-height: 5px;
    flex-grow: 1;
  }
}

</style>