export default {
    getBirthDate(codiceFiscale) {
        // Controlla che il codice fiscale abbia almeno 11 caratteri necessari
        if (codiceFiscale.length < 11) {
            return 'Codice fiscale non valido: troppo corto';
        }
    
        // Estrai l'anno
        var yearCode = codiceFiscale.substring(6, 8);
        var year = parseInt(yearCode, 10);
    
        // Determina il secolo
        var currentYear = new Date().getFullYear() % 100;
        var fullYear;
        if (year <= currentYear) {
            fullYear = 2000 + year;
        } else {
            fullYear = 1900 + year;
        }
    
        // Estrai il mese
        var monthCode = codiceFiscale.charAt(8).toUpperCase();
        var monthMap = {
            'A': 0,  // Gennaio
            'B': 1,  // Febbraio
            'C': 2,  // Marzo
            'D': 3,  // Aprile
            'E': 4,  // Maggio
            'H': 5,  // Giugno
            'L': 6,  // Luglio
            'M': 7,  // Agosto
            'P': 8,  // Settembre
            'R': 9,  // Ottobre
            'S': 10, // Novembre
            'T': 11  // Dicembre
        };
        var month = monthMap[monthCode];
        if (month === undefined) {
            return 'Codice fiscale non valido: codice mese non valido';
        }
    
        // Estrai il giorno e determina il sesso
        var dayCode = codiceFiscale.substring(9, 11);
        var dayNum = parseInt(dayCode, 10);
        var sex = 'M';
        if (dayNum > 40) {
            dayNum -= 40;
            sex = 'F';
        }
        
        // Crea un oggetto Date con i valori estratti
        var birthDate = new Date(fullYear, month, dayNum);
    
        // Controllo della validità della data (ad esempio, per giorni non validi come il 32)
        if (isNaN(birthDate.getTime())) {
            return 'Codice fiscale non valido: data non valida';
        }
    
        // Restituisce la data in formato completo, es: "Wed Oct 02 2024 00:00:00 GMT+0200"
        return birthDate;
    },
    
    getGender(taxCode) {
        // Verifichiamo che il codice fiscale sia lungo abbastanza per prendere i dati necessari
        if (taxCode.length >= 9) {
          const dayPart = taxCode.substring(9, 11); // Prendiamo i caratteri 10 e 11 (il giorno di nascita)
          const day = parseInt(dayPart, 10); // Convertiamo in numero
  
          // Verifica se il giorno indica maschio o femmina
          if (day >= 1 && day <= 31) {
            return 1;
          } else if (day >= 41 && day <= 71) {
            return 2;
          } else {
            return undefined;
          }
        } else {
          return undefined; // Codice troppo corto per determinare il sesso
        }
    }
};