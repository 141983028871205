<template>
    <div v-if="!statsMode">
        <DataTable :value="tableBookings" lazy paginator :first="tableFirst" :rows="10" v-model:filters="tableFilters" ref="bookingsTable" dataKey="id"
                :totalRecords="tableTotalRecords" :loading="tableLoading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="row"
                tableStyle="min-width: 100%" size="small" resizableColumns columnResizeMode="fit" :rowsPerPageOptions="[5, 10, 20, 50]" showGridlines class="mainTableContainer">

            <Column field="id" header="ID" filterMatchMode="contains" hidden>
            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Cerca"/>
            </template>
            </Column>
            <Column field="bookingNumber" header="Numero prenotazione" filterMatchMode="contains" sortable>
            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Cerca"/>
            </template>
            </Column>
            <Column field="createdAt" header="Ricevuta alle" data-type="date" filter-field="createdAt" sortable>
                <template #body="{ data }">
                    {{ formatDate(data.createdAt) }}
                </template>
                <!-- <template #filter="{ filterModel }">
                    <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
                </template> -->
            </Column>
            <Column field="fromLocation.address" header="Partenza" filterMatchMode="contains" style="max-width: 290px !important;" sortable>
            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Cerca"/>
            </template>
            </Column>
            <Column field="toLocation.address" header="Destinazione" filterMatchMode="contains" style="max-width: 290px !important;" sortable>
            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Cerca"/>
            </template>
            </Column>
            <Column field="bookingState" header="Stato" filterMatchMode="contains" sortable>
            <template #body="{data}">
                <transition name="blink-color" v-if="data.alertForExpirationSent && data.bookingState == 1">
                    <div class="blink-color">
                        <i class="pi pi-exclamation-circle text-red-500"></i>
                        &nbsp;
                        <span>In scadenza</span>
                    </div>
                </transition>
                <div v-else>
                    <i class="pi" :class="{
                    'pi-exclamation-triangle text-yellow-500': data.bookingState == 1,
                    'pi-check-circle text-green-500': data.bookingState == 2, 
                    'pi-times-circle text-red-400': data.bookingState == 3,
                    'pi-minus-circle text-red-400': data.bookingState == 4,
                    'pi-star text-green-500': data.bookingState == 5,
                    'pi-stopwatch text-red-400': data.bookingState == 6}">
                    </i>
                    &nbsp;
                    <span v-if="data.bookingState == 0">Errore</span>
                    <span v-if="data.bookingState == 1">In attesa</span>
                    <span v-if="data.bookingState == 2">Accettata</span>
                    <span v-if="data.bookingState == 3">Rifiutata</span>
                    <span v-if="data.bookingState == 4">Cancellata</span>
                    <span v-if="data.bookingState == 5">Completata</span>
                    <span v-if="data.bookingState == 6">Scaduta</span>
                </div>
                
            </template>
            </Column>
            <Column field="price" header="Prezzo" sortable>
            <template #body="{data}">
                <span v-if="data.price != null"><b>{{data.price}} €</b></span>
                <span v-else>Errore</span>
            </template>
            </Column>
            <Column field="id" header="Dettagli">
            <template #body="{data}">
                <Button size="small" label="Dettagli" icon="pi pi-info-circle" rounded class="p-button-label" @click="showBookingDetails(data.id)"></Button>
            </template>
            </Column>
            <Column field="id" header="Azioni">
            <template #body="{data}">
                <Button v-if="data.bookingState != 2" size="small" label="Accetta" icon="pi pi-check" rounded class="p-button-success" 
                        :disabled="data.bookingState >= 3" @click="answerBooking(data.id, true)"></Button>
                <Button v-if="data.bookingState == 2" size="small" label="Completa" icon="pi pi-check-circle" rounded class="p-button-info"
                        :disabled="data.bookingState >= 3" @click="completeBooking(data.id, true)"></Button>
                &nbsp;
                <Button v-if="data.bookingState != 2" size="small" label="Rifiuta" icon="pi pi-times" rounded class=" p-button-danger"
                        :disabled="data.bookingState >= 3" @click="answerBooking(data.id, false)"></Button>
                <Button v-if="data.bookingState == 2" size="small" label="Cancella" icon="pi pi-trash" rounded class="p-button-danger"
                        :disabled="data.bookingState >= 3" @click="completeBooking(data.id, false)"></Button>

            </template>
            </Column>
        </DataTable>
    </div>
    <div v-if="statsMode">
        <DataTable :value="tableBookings" lazy :first="tableFirst" :rows="5" v-model:filters="tableFilters" ref="bookingsTable" dataKey="id"
                :loading="tableLoading" @page="onPage($event)" tableStyle="min-width: 100%" size="small" resizableColumns columnResizeMode="fit" 
                showGridlines>
            <Column field="id" header="ID" filterMatchMode="contains" hidden></Column>
            <Column field="bookingNumber" header="Numero prenotazione" filterMatchMode="contains"></Column>
            <Column field="bookingState" header="Stato" filterMatchMode="contains">
            <template #body="{data}">
                <transition name="blink-color" v-if="data.alertForExpirationSent && data.bookingState == 1">
                    <div class="blink-color">
                        <i class="pi pi-exclamation-circle text-red-500"></i>
                        &nbsp;
                        <span>In scadenza</span>
                    </div>
                </transition>
                <div v-else>
                    <i class="pi" :class="{
                    'pi-exclamation-triangle text-yellow-500': data.bookingState == 1,
                    'pi-check-circle text-green-500': data.bookingState == 2, 
                    'pi-times-circle text-red-400': data.bookingState == 3,
                    'pi-minus-circle text-red-400': data.bookingState == 4,
                    'pi-star text-green-500': data.bookingState == 5,
                    'pi-stopwatch text-red-400': data.bookingState == 6}">
                    </i>
                    &nbsp;
                    <span v-if="data.bookingState == 0">Errore</span>
                    <span v-if="data.bookingState == 1">In attesa</span>
                    <span v-if="data.bookingState == 2">Accettata</span>
                    <span v-if="data.bookingState == 3">Rifiutata</span>
                    <span v-if="data.bookingState == 4">Cancellata</span>
                    <span v-if="data.bookingState == 5">Completata</span>
                    <span v-if="data.bookingState == 6">Scaduta</span>
                </div>
            </template>
            </Column>
            <Column field="price" header="Prezzo">
                <template #body="{data}">
                    <span v-if="data.price != null"><b>{{data.price}} €</b></span>
                    <span v-else>Errore</span>
                </template>
            </Column>
            <Column field="id" header="Dettagli">
            <template #body="{data}">
                <Button size="small" label="Dettagli" icon="pi pi-info-circle" rounded class="p-button-label" @click="showBookingDetails(data.id)"></Button>
            </template>
            </Column>
            <Column field="id" header="Azioni">
            <template #body="{data}">
                <Button v-if="data.bookingState != 2" size="small" label="Accetta" icon="pi pi-check" rounded class="p-button-success" 
                        :disabled="data.bookingState >= 3" @click="answerBooking(data.id, true)"></Button>
                <Button v-if="data.bookingState == 2" size="small" label="Completa" icon="pi pi-check-circle" rounded class="p-button-info"
                        :disabled="data.bookingState >= 3" @click="completeBooking(data.id, true)"></Button>
                &nbsp;
                <Button v-if="data.bookingState != 2" size="small" label="Rifiuta" icon="pi pi-times" rounded class=" p-button-danger"
                        :disabled="data.bookingState >= 3" @click="answerBooking(data.id, false)"></Button>
                <Button v-if="data.bookingState == 2" size="small" label="Cancella" icon="pi pi-trash" rounded class="p-button-danger"
                        :disabled="data.bookingState >= 3" @click="completeBooking(data.id, false)"></Button>

            </template>
            </Column>
        </DataTable>
    </div>
    <Toast ref="toast" position="bottom-right" style="right:0px; width: -webkit-fill-available; margin: 10px; max-width: 500px;"/>
    <Dialog v-model:visible="showBookingInfo" modal header="Dettagli Prenotazione" :style="{ width: '75rem' }">
        <BookingInfo :bookingId="bookingIdDetails"></BookingInfo>
    </Dialog>
</template>

<script>
    import API from '@/api/API';
    import Cookies from 'js-cookie';
    import BookingInfo from '../components/BookingInfo.vue';
    import Helper from '@/helper/Helpers';
    import { useConfirm } from 'primevue/useconfirm';
    import { FilterMatchMode, FilterOperator } from 'primevue/api';

    export default {
        name: 'BookingsDataTable',

        props: {
            statsMode: {
                type: Boolean,
                required: true
            }
        },
        data(){
            return{
            tableLoading: false,
            tableBookings: [],
            tableTotalRecords: 0,
            tableFirst: 0,
            tableFilters: {
                'id': {value: '', matchMode: 'contains', operator: ''},
                'bookingNumber': {value: '', matchMode: 'contains', operator: ''},
                'fromLocation.address': {value: '', matchMode: 'contains', operator: ''},
                'toLocation.address': {value: '', matchMode: 'contains', operator: ''},
                'createdAt': {value: '', matchMode: 'contains', operator: ''},
            },
            lazyParams: {},

            confirmDialog: useConfirm(),
            bookingIdDetails: null,
            showBookingInfo: false,
            }
        },
        components: {
            BookingInfo,
        },
        mounted() {
            this.tableLoading = true;

            this.lazyParams = {
                first: 0,
                rows: this.statsMode ? 7 : 10,
                sortField: 'createdAt',
                sortOrder: 1,
                filters: this.tableFilters
            };

            this.loadLazyData();
        },
        methods: {
            formatDate(date) {
                return Helper.TimeHelper.getDateFromDateTime(new Date(date)) + ' ' + Helper.TimeHelper.getTimeFromDateTime(new Date(date));
            },
            loadLazyData() {
                const jwt = Cookies.get('token');
                const companyId = Cookies.get('companyId');

                if (jwt === undefined)
                {
                    console.error('Token non presente');
                }

                if (companyId === undefined)
                {
                    console.error('CompanyId non presente');
                }

                API.BookingAPI.GetBookingsDataTable(this.lazyParams, companyId, jwt)
                    .then(response => {
                        this.tableBookings = response.data.data;
                        this.tableTotalRecords = response.data.totalRecords;
                        this.tableLoading = false;
                    })
                    .catch(error => {
                        console.error(error);
                        this.tableLoading = false;
                    });
            },
            onPage(event) {
                this.lazyParams = event;
                this.loadLazyData();
            },
            onSort(event) {
                this.lazyParams = event;
                this.loadLazyData();
            },
            onFilter(event) {
                this.lazyParams = event;
                this.loadLazyData();
            },
            showBookingDetails(bookingId) {
                this.bookingIdDetails = bookingId;
                this.showBookingInfo = true;
            },
            answerBooking(bookingId, accept) {
                const jwt = Cookies.get('token');

                if (jwt === undefined)
                {
                    console.error('Token non presente');
                }

                this.confirmDialog.require({
                    message: `Sicuro di voler ${accept ? 'accettare' : 'rifiutare'} la prenotazione?`,
                    header: 'Attenzione',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClass: 'p-button-secondary p-button-outlined',
                    rejectLabel: 'No',
                    acceptLabel: 'Si',
                    accept: () => {
                        this.sendAnswerAPI(jwt, bookingId, accept);
                    },
                    reject: () => {
                    }
                });
            },
            sendAnswerAPI(jwt, bookingId, accept) {
                API.BookingAPI.AnswerBooking(jwt, bookingId, accept)
                    .then(response => {
                        if (accept)
                        {
                            this.$refs.toast.add({severity:'success', summary: 'Prenotazione', detail: 'Prenotazione accettata',life: 1000});
                        }
                        else
                        {
                            this.$refs.toast.add({severity:'success', summary: 'Prenotazione', detail: 'Prenotazione rifiutata',life: 1000});
                        }
                        this.loadLazyData();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            completeBooking(bookingId, accept) {
                const jwt = Cookies.get('token');
                const companyId = Cookies.get('companyId');

                if (jwt === undefined)
                {
                    console.error('Token non presente');
                }

                if (companyId === undefined)
                {
                    console.error('CompanyId non presente');
                }

                this.confirmDialog.require({
                    message: `Sicuro di voler ${accept ? 'completare' : 'cancellare'} la prenotazione?`,
                    header: 'Attenzione',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClass: 'p-button-secondary p-button-outlined',
                    rejectLabel: 'No',
                    acceptLabel: 'Si',
                    accept: () => {
                        this.sendCompleteAPI(jwt, bookingId, accept);
                    },
                    reject: () => {
                    }
                });
            },
            sendCompleteAPI(jwt, bookingId, accept) {
                API.BookingAPI.CompleteBooking(jwt, bookingId, accept)
                    .then(response => {
                        this.loadLazyData();
                    })
                    .catch(error => {
                        if (error.response.status === 400)
                        {
                            this.$refs.toast.add({severity:'error', summary: 'Errore', detail: 'Impossibile completare la prenotazione',life: 3500});
                        }
                        else if (error.response.status === 425)
                        {
                            this.$refs.toast.add({ severity: 'error', summary: 'Errore', detail: 'Non è possibile completare una prenotazione prima dell\'orario di ritorno previsto.', life: 3500});
                        }
                        else
                        {
                            console.error(error);
                        }
                    });
            }
        }
    };
</script>



<style scoped>
@keyframes blinkColor {
  0% {
    color: red;
  }
  50% {
    color: #4b5563;
  }
  100% {
    color: red;
  }
}

.blink-color {
  animation: blinkColor 2s infinite;
}

:deep(.p-column-filter-menu-button.p-link) {
    display: none !important;
}

:deep(.p-column-filter-clear-button.p-link.p-hidden-space) {
    display: none !important;
}

:deep(.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child){
    text-align: center;
}
:deep(.p-button){
    width: -webkit-fill-available;
}
:deep(.p-button.p-button-info) { 
    width: 45%;
    background-color:#4F5D75;
    border-color: #4F5D75;
}
:deep(.p-button.p-button-info:hover) { 
    background-color:#4f5d75ce;
    border-color: #ffffffce;
}
:deep(.p-button.p-button-success){
    width: 45%;
}
:deep(.p-button.p-button-danger){
    width: 45%;
}
.mainTableContainer{
    padding: 15px;
    background-color: aquamarine;
    padding: 50px;
    border-radius: 30px;
    box-shadow: 0px 0px 50px 7px rgba(0,0,0,0.1);
    position: relative;
    text-align: center;
    background-color: white;
    width: -webkit-fill-available;
    padding-bottom: 0px;
    margin-top: 50px;
}
@media (max-height: 900px) {
    .mainTableContainer{
        zoom: 65%;
    }
}

</style>